import * as React from 'react'

import { Button } from '@astrid/components'
import { Menu, MenuItem } from '@material-ui/core'

type Props = {
  onDelete?: () => void
}

export const ClassViewMenu = ({ onDelete }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDelete = () => {
    handleClose()
    onDelete?.()
  }

  return (
    <div>
      <Button
        variant="flat"
        id="class-view-actions"
        aria-controls={open ? 'class-view-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        Actions
      </Button>
      <Menu
        id="class-view-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'class-view-actions'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <MenuItem disabled={!onDelete} onClick={handleDelete}>
          Delete class
        </MenuItem>
      </Menu>
    </div>
  )
}
