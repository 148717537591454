import { appConfig } from 'shared/appConfig'

import AuthApi from 'store/services/Auth/authApi'

import ApiService from './ApiService'

export const ACCESS_TOKEN_KEY = 'Authorization'

const API = new ApiService({
  API_URL: appConfig.apiUrl,
  DEFAULT_HTTP_HEADERS: {
    'Content-Type': 'application/json'
  },
  ACCESS_TOKEN_KEY: ACCESS_TOKEN_KEY,
  GET_ACCESS_TOKEN: () => AuthApi.getUserToken()
}).service

export default API
