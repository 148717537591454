import { ApiReqState } from 'shared/api/types'

import { TimePeriod } from 'store/types'

export interface OverviewState {
  readonly classOverview: StatsOverview | null
  readonly studentOverview: StatsOverview | null
  readonly classReqState: ApiReqState
  readonly studentReqState: ApiReqState
  readonly timePeriod: TimePeriod
}
export interface StatsOverview {
  readonly stats: {
    effort: Effort
    progress: Progress
  } | null
  readonly words: {
    trendingHashtags: Hashtag[]
    challengingWordsToSpell: ChallengingWord[]
    challengingWordsToPronounce: ChallengingWord[]
    challengingSounds: ChallengingWord[]
  } | null
  readonly analytics: {
    progress: ProgressAnalytics
    effort: EffortAnalytics
  } | null
}

export enum OVERVIEW_ACTIONS {
  LOAD_STUDENT = 'OVERVIEW/load_student',
  LOAD_CLASS = 'OVERVIEW/load_class',
  LOAD_CLASS_REQ_STATE = 'OVERVIEW/load_class_req_state',
  LOAD_STUDENT_REQ_STATE = 'OVERVIEW/load_student_req_state',
  SET_TIME_PERIOD = 'OVERVIEW/set_time_period',
  CLEAR = 'OVERVIEW/clear'
}

// Typings:
export interface OverviewDetails {
  userGoal: number
  userScore: number
}

export interface Effort {
  timeSpent: OverviewDetails
  booksCompleted: OverviewDetails
  exercisesCompleted: OverviewDetails
}

export interface Progress {
  fluency: OverviewDetails
  comprehension: OverviewDetails
}

export interface ProgressAnalytics {
  comprehension: AnalyticsData
  fluency: AnalyticsData
}

export interface EffortAnalytics {
  booksCompleted: AnalyticsData
  exercisesCompleted: AnalyticsData
  timeSpent: AnalyticsData
}

export type AnalyticsScore = {
  time: number
  score: number
}

export type AnalyticsData = {
  userGoal: number
  userScore: AnalyticsScore[]
}

export interface Hashtag {
  count: number
  name: string
}

export interface ChallengingWord {
  avgScore: number
  name: string
}

export interface Overview {
  stats: {
    effort: Effort
    progress: Progress
  }
  words: {
    trendingHashtags: Hashtag[]
    challengingWordsToSpell: ChallengingWord[]
    challengingWordsToPronounce: ChallengingWord[]
    challengingSounds: ChallengingWord[]
  }
}

// Actions:

export interface LoadClassOverviewAction {
  type: OVERVIEW_ACTIONS.LOAD_CLASS
  data: Overview & { analytics: { progress: ProgressAnalytics; effort: EffortAnalytics } | null }
}

export interface LoadStudentOverviewAction {
  type: OVERVIEW_ACTIONS.LOAD_STUDENT
  data: Overview & { analytics: { progress: ProgressAnalytics; effort: EffortAnalytics } | null }
}

export interface LoadClassOverviewActionReqStateAction {
  type: OVERVIEW_ACTIONS.LOAD_CLASS_REQ_STATE
  reqState: ApiReqState
}

export interface LoadStudentOverviewActionReqStateAction {
  type: OVERVIEW_ACTIONS.LOAD_STUDENT_REQ_STATE
  reqState: ApiReqState
}

export interface SetTimePeriodAction {
  type: OVERVIEW_ACTIONS.SET_TIME_PERIOD
  timePeriod: TimePeriod
}

export interface ClearOverviewAction {
  type: OVERVIEW_ACTIONS.CLEAR
}

export type OverviewActions =
  | LoadClassOverviewAction
  | LoadStudentOverviewAction
  | LoadClassOverviewActionReqStateAction
  | LoadStudentOverviewActionReqStateAction
  | SetTimePeriodAction
  | ClearOverviewAction
