import React, { PropsWithChildren } from 'react'

import styles from './StudyTimeCell.module.scss'

const YELLOW_STUDY_TIME_RATIO = 1 / 6

interface Props {
  goal?: number
  score?: number
}

const decideClass = (goal?: number, score?: number): string => {
  if (score === undefined) return styles.studyTimeLow
  if (goal === undefined) return styles.studyTimeHigh
  if (score < YELLOW_STUDY_TIME_RATIO * goal) return styles.studyTimeLow
  if (score < goal) return styles.studyTimeMiddle
  return styles.studyTimeHigh
}

export const StudyTimeCell = ({ score, goal, children }: PropsWithChildren<Props>) => {
  return <td className={decideClass(goal, score)}>{children}</td>
}

export default StudyTimeCell
