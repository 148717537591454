import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AlertContext, CancelConfirmButtons, TextInput, Typography, ValidationRules } from '@astrid/components'
import { Radio } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { ROUTES } from 'routes/routes'
import { printApiMessage } from 'shared/api/apiMessages'
import * as Yup from 'yup'

import { createClass } from 'store/services/Classes/reducer'

import styles from './CreateClass.module.scss'

interface FormFields {
  name: string
  cefrLevel: string
}

const validationSchema = Yup.object().shape({
  name: ValidationRules.required.max(50, 'Class name must be at most 50 chars.'),
  cefrLevel: ValidationRules.required
})

const CreateClass = ({ onCancel }: { onCancel: () => void }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { showAlert } = useContext(AlertContext)

  const handleSubmit = useCallback(
    async (formValues: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      const { name, cefrLevel } = formValues
      try {
        const id = await dispatch(createClass({ name, cefrLevel }))
        history.push(`${ROUTES.CLASSES}/${id}`)
      } catch {
        showAlert(printApiMessage('Some values might be incorrect or connection problem occured.'))
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    []
  )

  const radioAndLabel = (level, text, currentLevel, setFieldValue) => (
    <label>
      <Radio
        checked={currentLevel === level}
        onChange={() => setFieldValue('cefrLevel', level)}
        value={level}
        name="cefrLevel"
        color="primary"
        data-testid={`cefr-radio-${level}`}
      />
      {text}
    </label>
  )

  return (
    <div className={styles.container}>
      <Typography variant="h2" component="h1">
        Create a class
      </Typography>
      <Formik
        initialValues={{
          name: '',
          cefrLevel: 'A1.1'
        }}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
        onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting, setFieldValue }) => {
          const cefrLevel = values.cefrLevel
          return (
            <Form>
              <div className={styles.wrapper}>
                <TextInput
                  name="name"
                  label="Class name"
                  value={values.name}
                  onChange={handleChange}
                  helperText={(touched.name && errors.name) || ''}
                  error={touched.name && !!errors.name}
                  onBlur={() => setFieldTouched('name')}
                />
                <Typography className={styles.questionText} variant="exerciseS" component="div">
                  What do you expect these students to be able to do in English?
                </Typography>
                <ol className={styles.list}>
                  {radioAndLabel(
                    'A1.1',
                    'A1: Use common expressions and very basic phrases.',
                    cefrLevel,
                    setFieldValue
                  )}
                  {radioAndLabel('A2.1', 'A2: Talk about things they see and want.', cefrLevel, setFieldValue)}
                  {radioAndLabel('B1.1', 'B1: Describe their opinions and plans.', cefrLevel, setFieldValue)}
                </ol>
              </div>
              <CancelConfirmButtons
                disableConfirm={isSubmitting}
                onCancel={onCancel}
                onConfirm={handleSubmit}
                confirmText="Create"
                className={styles.confirm}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateClass
