import React, { useCallback, useState } from 'react'
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom'

import { Button, HelperText, TextInput, Typography, ValidationRules } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { ScreenNames } from 'analytics/analytics'
import axios from 'axios'
import { Formik, FormikHelpers } from 'formik'
import { ROUTES } from 'routes/routes'
import * as Yup from 'yup'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import AuthApi from 'store/services/Auth/authApi'

import styles from './ResetPassword.module.scss'

const validationSchema = Yup.object().shape({
  password: ValidationRules.password,
  password2: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required')
})

interface FormFields {
  password: string
  password2: string
}

enum ResetPasswordResult {
  None,
  Success,
  FailedUnauthorized,
  FailedOther
}

const ResetPassword = ({ match, history }: RouteComponentProps<{ resetToken: string }>) => {
  useTrackScreenShown(ScreenNames.ResetPassword)
  const { resetToken } = match.params
  const [result, setResult] = useState<ResetPasswordResult>(ResetPasswordResult.None)
  if (!resetToken || resetToken === '') {
    history.push(ROUTES.ROOT)
  }

  const handleSubmit = useCallback(
    async ({ password }: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      setResult(ResetPasswordResult.None)
      try {
        await AuthApi.setNewPassword(password, resetToken)
        setResult(ResetPasswordResult.Success)
      } catch (error) {
        const result =
          axios.isAxiosError(error) && error.response?.status === 401
            ? ResetPasswordResult.FailedUnauthorized
            : ResetPasswordResult.FailedOther
        setSubmitting(false)
        setResult(result)
      }
    },
    [setResult, resetToken]
  )

  return (
    <AuthWrapper>
      {result === ResetPasswordResult.Success ? (
        <>
          <Typography variant="h3">The password has been set.</Typography>
          <Typography variant="body" color="secondary">
            You can now <NavLink to={ROUTES.ROOT}>log in</NavLink>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">Set new password for your account</Typography>
          <Formik
            initialValues={{ password: '', password2: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    name="password"
                    label="New password"
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    error={touched.password && !!errors.password}
                    helperText={(touched.password && errors.password) || ''}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <TextInput
                    name="password2"
                    label="Confirm new password"
                    type="password"
                    value={values.password2}
                    onChange={handleChange('password2')}
                    error={touched.password2 && !!errors.password2}
                    helperText={(touched.password2 && errors.password2) || ''}
                    onBlur={() => setFieldTouched('password2')}
                  />
                  <Button
                    color="black"
                    variant="flat"
                    type="submit"
                    disabled={isSubmitting}
                    className={styles.submitButton}>
                    Set password
                  </Button>
                </form>
              )
            }}
          </Formik>

          {result === ResetPasswordResult.FailedUnauthorized ? (
            <span className={`helper-text helper-text--error ${styles.errorMessage}`}>
              Your password reset link may have expired.
              <br />
              Please try sending yourself a new one from <Link to={ROUTES.REQUEST_PASSWORD_RESET}>here</Link>
            </span>
          ) : result === ResetPasswordResult.FailedOther ? (
            <HelperText
              text="Sorry! An unexpected error occurred - please reach out to support if the problem persists. "
              error
            />
          ) : null}
        </>
      )}
    </AuthWrapper>
  )
}

export default withRouter(ResetPassword)
