import * as Sentry from '@sentry/react'
import { Dispatch } from 'redux'
import { getError } from 'shared/helpers/utils'
import { Logger } from 'shared/logger/Logger'

import AuthApi from 'store/services/Auth/authApi'
import {
  AUTH_ACTIONS,
  AuthActions,
  AuthCredentials,
  AuthLoadUser,
  AuthState,
  Branding,
  TeacherProfile,
  TeacherProfileUpdate,
  TeacherSettings
} from 'store/services/Auth/types'
import { RootState } from 'store/types'

export const AuthInitialState: AuthState = {
  user: undefined
}

const defaultSettings: TeacherSettings = {
  allowClassManagement: true,
  googleClassroomFeatures: false,
  showGeseCatagories: false,
  availableContentFrameworks: []
}

const authReducer = (state: AuthState = AuthInitialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.LOAD_DATA:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}

export default authReducer

// ACTIONS
export const loginUser =
  (data: AuthCredentials & { captchaToken: string }) => async (dispatch: Dispatch<AuthLoadUser>) => {
    try {
      const { token, user } = (await AuthApi.login(data)).data

      Sentry.setUser({ id: user._id, branding: user.settings.branding })

      dispatch({
        type: AUTH_ACTIONS.LOAD_DATA,
        user
      })

      AuthApi.setUserToken(token)
      return user
    } catch (e) {
      Logger.log(e)
      throw getError(e)
    }
  }

export const createUser = (data: AuthCredentials) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { token, user } = (await AuthApi.create(data)).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
    AuthApi.setUserToken(token)
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const createProfile = (profile: TeacherProfile) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.createProfile(profile)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const setOnboardinCompleted = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.setOnboardingCompleted()
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const setTutorialCompleted = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.setTutorialCompleted()
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const getUser = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.load()
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const updateUser = (updateData: TeacherProfileUpdate) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.updateProfile(updateData)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

export const uploadAvatar = (updateData: FormData) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data: user } = await AuthApi.setProfileAvatar(updateData)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw getError(e)
  }
}

// SELECTORS
export const selectUser = (state: RootState) => state.auth.user

export const selectUserSettings = (state: RootState) => state.auth.user?.settings ?? defaultSettings

export const selectUserCountry = (state: RootState) => state.auth.user?.profile?.country

export const selectBranding = (state: RootState) => state.auth.user?.settings.branding ?? Branding.ASTRID

export const selectAvailableContentFrameworks = (state: RootState) =>
  state.auth.user?.settings.availableContentFrameworks ?? []
