import { Dispatch } from 'redux'
import { ApiReqState } from 'shared/api/types'
import { Logger } from 'shared/logger/Logger'

import { RootState } from 'store/types'

import ClassesApi from './api'
import {
  CLASSES_ACTIONS,
  ClassesActions,
  ClassesState,
  CreateStudentData,
  LoadClassesAction,
  LoadCurrentClassAction,
  LoadCurrentClassReqStateAction,
  RemoveClassAction,
  SetInsightClassIdAction,
  StudentClassData
} from './types'

export const ClassesInitialState: ClassesState = {
  classes: [],
  currentClass: null,
  currentClassReqState: ApiReqState.IDLE,
  insightClassId: ''
}

const classesReducer = (state: ClassesState = ClassesInitialState, action: ClassesActions) => {
  switch (action.type) {
    case CLASSES_ACTIONS.LOAD_CLASSES:
      return {
        ...state,
        classes: [...action.classes].sort((a, b) => a.name.localeCompare(b.name))
      }
    case CLASSES_ACTIONS.REMOVE_CLASS:
      return {
        ...state,
        classes: state.classes.filter((c) => c._id !== action.classId)
      }
    case CLASSES_ACTIONS.LOAD_CURRENT_CLASS:
      return {
        ...state,
        currentClass: action.class
      }
    case CLASSES_ACTIONS.LOAD_CURRENT_CLASS_REQ_STATE:
      return {
        ...state,
        currentClassReqState: action.reqState
      }
    case CLASSES_ACTIONS.SET_INSIGHT_CLASS_ID:
      return {
        ...state,
        insightClassId: action.insightClassId
      }
    default:
      return state
  }
}

export default classesReducer

// Action creators:

export const getClasses = () => async (dispatch: Dispatch<LoadClassesAction>) => {
  try {
    const {
      data: { results }
    } = await ClassesApi.getClasses()
    dispatch({
      type: CLASSES_ACTIONS.LOAD_CLASSES,
      classes: results
    })
  } catch (error) {
    Logger.log(error)
  }
}

const setGetCurrentClassReqState = (reqState: ApiReqState): LoadCurrentClassReqStateAction => ({
  type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS_REQ_STATE,
  reqState
})

export const getCurrentClass =
  (id: string) => async (dispatch: Dispatch<LoadCurrentClassAction | LoadCurrentClassReqStateAction>) => {
    try {
      dispatch(setGetCurrentClassReqState(ApiReqState.PENDING))
      const { data } = await ClassesApi.getClassById(id)

      // Auto-sync external student class
      const classData = data.externalRef
        ? await ClassesApi.importGoogleClassroomCourse({
            googleClassId: data.externalRef.id,
            cefrLevel: data.cefrLevel
          })
            .then((resp) => resp.data)
            .catch((err) => {
              // better to fall back to the one we already got, sync can be requested manually:
              console.warn('Auto sync external class failed', err)
              return data
            })
        : data
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: classData
      })
      dispatch(setGetCurrentClassReqState(ApiReqState.RESOLVED))
    } catch (error) {
      dispatch(setGetCurrentClassReqState(ApiReqState.REJECTED))
      Logger.log(error)
      throw error
    }
  }

export const clearCurrentClass = (dispatch: Dispatch<LoadCurrentClassAction>) => {
  dispatch({
    type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
    class: null
  })
}

export const createClass = (classData: StudentClassData) => async (dispatch: Dispatch<LoadCurrentClassAction>) => {
  try {
    const { data } = await ClassesApi.create(classData)
    dispatch({
      type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
      class: { ...data, users: [] }
    })
    return data._id
  } catch (error) {
    Logger.log(error)
    throw typeof error === 'string' ? new Error(error) : error
  }
}

export const addStudentToClass =
  (classId: string, studentData: CreateStudentData) => async (dispatch: Dispatch<LoadCurrentClassAction>) => {
    try {
      const { data } = await ClassesApi.addStudentToClass(classId, studentData)
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: data
      })
    } catch (error) {
      Logger.log(error)
      throw typeof error === 'string' ? new Error(error) : error
    }
  }

export const removeClass = (classId: string) => async (dispatch: Dispatch<RemoveClassAction>) => {
  try {
    await ClassesApi.deleteClass(classId)
    dispatch({
      type: CLASSES_ACTIONS.REMOVE_CLASS,
      classId: classId
    })
  } catch (error) {
    Logger.log(error)
  }
}

export const updateCurrentClass =
  (id: string, classData: Partial<Pick<StudentClassData, 'name' | 'englishDialect'>>) =>
  async (dispatch: Dispatch<LoadCurrentClassAction>) => {
    try {
      const { data } = await ClassesApi.updateClass(id, classData)
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: data
      })
    } catch (error) {
      Logger.log(error)
      throw typeof error === 'string' ? new Error(error) : error
    }
  }

export const editStudent =
  (classId: string, studentId: string, studentData: CreateStudentData) =>
  async (dispatch: Dispatch<LoadCurrentClassAction>) => {
    try {
      const { data } = await ClassesApi.editStudent(classId, studentId, studentData)
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: data
      })
    } catch (error) {
      Logger.log(error)
      throw typeof error === 'string' ? new Error(error) : error
    }
  }

export const removeStudentFromClass =
  (classId: string, studentId: string) => async (dispatch: Dispatch<LoadCurrentClassAction>) => {
    try {
      const { data } = await ClassesApi.deleteStudentFromClass(classId, studentId)
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: data
      })
    } catch (error) {
      Logger.log(error)
      throw typeof error === 'string' ? new Error(error) : error
    }
  }

export const addMultipleStudents =
  (classId: string, students: CreateStudentData[]) => async (dispatch: Dispatch<LoadCurrentClassAction>) => {
    try {
      const { data } = await ClassesApi.addMultipleStudents(classId, students)
      dispatch({
        type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS,
        class: data
      })
    } catch (error) {
      Logger.log(error)
      throw typeof error === 'string' ? new Error(error) : error
    }
  }

export const setInsightClassId = (classId: string) => (dispatch: Dispatch<SetInsightClassIdAction>) =>
  dispatch({
    type: CLASSES_ACTIONS.SET_INSIGHT_CLASS_ID,
    insightClassId: classId
  })

// Selectors:

export const selectClasses = (state: RootState) => state.classes.classes
export const selectCurrentClass = (state: RootState) => state.classes.currentClass
export const selectCurrentClassReqState = (state: RootState) => state.classes.currentClassReqState
export const selectInsightClassId = (state: RootState) => state.classes.insightClassId
