import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertContext, BookContext, BookType, Button, Modal, Typography } from '@astrid/components'
import { ReactComponent as CopyIcon } from 'shared/assets/copy.svg'

import { getKidsAppDeepLinkForBook } from '../../../Book/api'
import { printApiMessage } from '../../../shared/api/apiMessages'
import { LibraryBookEntry } from '../../../store/services/Library'
import styles from './LibraryBookModal.module.scss'

type LibraryBookModalProps = {
  onClose: () => void
  bookEntry: LibraryBookEntry | null
}

export const LibraryBookModal = ({ bookEntry, onClose }: LibraryBookModalProps) => {
  const { showAlert } = useContext(AlertContext)
  const history = useHistory()

  const [copyState, setCopyState] = useState({ reading: false, listening: false })

  const bookId = bookEntry?.bookId
  const copyDeepLinking = useCallback(
    async (mode: BookType.Reading | BookType.Listening) => {
      try {
        if (!bookId) {
          throw Error('Missing content ID')
        }
        const { data: deepLink } = await getKidsAppDeepLinkForBook(bookId, mode)
        setCopyState({ listening: mode === BookType.Listening, reading: mode === BookType.Reading })
        navigator.clipboard.writeText(deepLink.linkUrl)
      } catch (err) {
        showAlert(printApiMessage(err))
      }
    },
    [bookId, setCopyState, showAlert]
  )

  const closeModal = () => {
    setCopyState({ listening: false, reading: false })
    onClose()
  }

  return (
    <Modal open={!!bookEntry} variant="teacher" hideCloseButton closeModal={closeModal}>
      <Typography variant="h3" className={styles.modalHeader}>
        {bookEntry?.title}
      </Typography>

      <Typography variant="body" className={styles.modalTitle}>
        Start activity:
      </Typography>

      <div className={styles.modalButtons}>
        <Button
          variant="flat"
          color="default"
          onClick={() => history.push(`/book/${BookContext.reading}/${bookEntry?.bookId}`)}>
          Start reading
        </Button>

        <Button
          variant="flat"
          color="default"
          onClick={() => history.push(`/book/${BookContext.listening}/${bookEntry?.bookId}`)}>
          Start listening
        </Button>
      </div>

      <Typography variant="body" className={styles.modalTitle}>
        Copy assignment link:
      </Typography>

      <div className={styles.copyButtons}>
        <Button
          variant="flat"
          color="black"
          onClick={() => void copyDeepLinking(BookType.Reading)}
          className={styles.copyButton}
          aria-label="Copy link to reading version">
          Reading{' '}
          {copyState.reading ? '✓' : <CopyIcon className={styles.copyIcon} aria-hidden="true" focusable="false" />}
        </Button>
        <Button
          variant="flat"
          color="black"
          onClick={() => void copyDeepLinking(BookType.Listening)}
          className={styles.copyButton}
          aria-label="Copy link to listening version">
          Listening{' '}
          {copyState.listening ? '✓' : <CopyIcon className={styles.copyIcon} aria-hidden="true" focusable="false" />}
        </Button>
      </div>
    </Modal>
  )
}
