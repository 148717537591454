import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Typography } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { ScreenNames } from 'analytics/analytics'
import heroImage from 'shared/assets/abbe-happy.png'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import AuthApi from 'store/services/Auth/authApi'

const ActivateEmail = ({ match }: RouteComponentProps<{ email?: string }>) => {
  useTrackScreenShown(ScreenNames.ActivateEmail)
  const [resend, setResend] = useState(false)
  const email = match.params.email

  const resendEmail = useCallback(async () => {
    try {
      await AuthApi.resendVerificationEmail()
      setResend(true)
    } catch (error) {}
  }, [])
  return (
    <AuthWrapper noBackground>
      <img src={heroImage} alt={''} />
      <Typography variant="h1" style={{ margin: 0 }}>
        Activate your account
      </Typography>
      <Typography variant="body">We’ve sent an email with your activation link to {email}</Typography>
      <Typography variant="body" color="secondary">
        {resend ? (
          'Activation link has been resent. Please check your email or contact us on support@astrideducation.com in case of further problems.'
        ) : (
          <>
            Didn’t receive the email?{' '}
            <span style={{ color: '#0000EE', cursor: 'pointer' }} onClick={resendEmail}>
              Resend email
            </span>{' '}
            or contact us on <a href={'mailto:support@astrideducation.com'}>support@astrideducation.com</a>
          </>
        )}
      </Typography>
    </AuthWrapper>
  )
}
export default ActivateEmail
