import { Book, BookType, GetAstridScoreOptions, IExercise, ScoreResponse } from '@astrid/components'
import { AxiosPromise } from 'axios'
import API from 'shared/api/API'
import { Paginated } from 'shared/api/types'

export const getAllBooks = (): AxiosPromise<Paginated<Book>> =>
  API.get('/book', {
    params: {
      $limit: 1000
    }
  })

export const getBook = (id: string): AxiosPromise<Book> => API.get(`/book/${id}`)

export const getKidsAppDeepLinkForBook = (bookId: string, bookType: BookType.Reading | BookType.Listening) =>
  API.post(`/content-link`, { activity: { bookId, bookType }, appName: 'kid' })

export const getBookExercises = (bookId: string): AxiosPromise<IExercise[]> => API.get(`/book/${bookId}/exercises`)

export const getAstridScore = ({ audio, phrase }: GetAstridScoreOptions): AxiosPromise<ScoreResponse> => {
  const formData = new FormData()
  formData.append('audio', audio)
  formData.append('target_phrase', phrase)
  formData.append('dialect', 'en-GB')

  return API.post('/astrid/score', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}
