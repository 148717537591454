import { ApiErrorCodes } from './ApiErrorCodes'

export const getError = (error: unknown) => {
  let errorMessage = 'Something went wrong.'

  if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  }

  return new Error(errorMessage)
}

export const checkIsUnauthorized = (error: unknown) => {
  const errorMessage = getError(error).message
  return errorMessage.includes('401') || errorMessage.includes('404') || errorMessage === ApiErrorCodes.GeneralAuthError
}
