import React, { useCallback, useContext, useMemo, useState } from 'react'

import { AlertContext, Card, Typography } from '@astrid/components'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { ReactComponent as DeleteIcon } from 'shared/assets/delete.svg'
import { ReactComponent as EditIcon } from 'shared/assets/edit.svg'

import { StudentsTableRow } from 'store/services/Classes/types'

import styles from './StudentsTable.module.scss'
import SingleStudentForm, { SingleStudentFormValues } from './components/SingleStudentForm'
import SortableColumnHeader from './components/SortableColumnHeader'
import StudentTableToolbar from './components/StudentTableToolbar'

export type SortingOrder = 'asc' | 'dsc'

interface StudentsTableProps {
  students: StudentsTableRow[]
  canEdit: boolean
  exportStudentsUrl: string
  onAddStudent: (values: SingleStudentFormValues) => Promise<void>
  onEditStudent: (studentId: string, values: SingleStudentFormValues) => Promise<void>
  onRegeneratePassword: (studentId: string) => Promise<string | null>
  onDeleteStudent: (student: StudentsTableRow) => void
  onUploadClassList: () => void
}

interface SortState {
  key: keyof StudentsTableRow
  order: SortingOrder
}

const StudentsTable = ({
  students,
  canEdit,
  onAddStudent,
  exportStudentsUrl,
  onEditStudent,
  onRegeneratePassword,
  onDeleteStudent,
  onUploadClassList
}: StudentsTableProps) => {
  const [showAddStudentForm, setShowAddStudentForm] = useState(false)
  const [sortState, setSortState] = useState<SortState>({ key: 'firstName', order: 'dsc' })
  const [editId, setEditId] = useState<string | null>(null)

  const { showAlert } = useContext(AlertContext)
  const _onAddStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      try {
        await onAddStudent(values)
        setShowAddStudentForm(false)
      } catch (error) {}
    },
    [onAddStudent]
  )

  const _onEditStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      if (editId) {
        try {
          await onEditStudent(editId, values)
          setEditId(null)
        } catch (error) {
          showAlert('Editing student error. Please try again.')
        }
      }
    },
    [showAlert, editId, onEditStudent]
  )

  const sortedStudents = useMemo(() => {
    return students.sort((a, b) => {
      return (sortState.order === 'asc' ? -1 : 1) * (a[sortState.key] || '').localeCompare(b[sortState.key] || '')
    })
  }, [students, sortState])

  const changeSorting = useCallback((key: keyof StudentsTableRow) => {
    setSortState((prevSortState) => {
      if (prevSortState.key === key) {
        return {
          ...prevSortState,
          order: prevSortState.order === 'asc' ? 'dsc' : 'asc'
        }
      } else {
        return {
          key,
          order: 'dsc'
        }
      }
    })
  }, [])

  return (
    <Card className={styles.card}>
      <StudentTableToolbar
        canEdit={canEdit}
        onAddStudent={() => canEdit && setShowAddStudentForm(true)}
        exportStudentsUrl={exportStudentsUrl}
        studentsCount={students.length}
        onUploadClassList={onUploadClassList}
      />
      <Table className={styles.table}>
        <TableHead className={styles.head}>
          <TableRow>
            <SortableColumnHeader
              label="First name"
              name="firstName"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Last name"
              name="lastName"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Username"
              name="username"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Password"
              name="password"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            {canEdit ? (
              <TableCell align="right">
                <span className={styles.headerCell__actions}>Actions</span>
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {showAddStudentForm && (
            <SingleStudentForm onSubmit={_onAddStudent} onCancel={() => setShowAddStudentForm(false)} />
          )}
          {sortedStudents.map((row) => {
            if (editId && row._id === editId) {
              return (
                <SingleStudentForm
                  key={row._id}
                  onSubmit={_onEditStudent}
                  onCancel={() => setEditId(null)}
                  initialValues={{
                    firstName: row.firstName,
                    lastName: row.lastName,
                    username: row.username,
                    password: row.password
                  }}
                  canRegeneratePassword={!!row.password.length}
                  onRegeneratePassword={() => onRegeneratePassword?.(row._id)}
                />
              )
            } else {
              return (
                <TableRow key={row.username} className={styles.row}>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.password}</TableCell>
                  {canEdit ? (
                    <TableCell align="right">
                      <Tooltip interactive title="Edit student">
                        <IconButton
                          onClick={() => setEditId(row._id)}
                          data-testid="student-edit-button"
                          aria-label="Edit student">
                          <EditIcon role="img" aria-label="Edit Icon" focusable="false" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip interactive title="Delete student">
                        <IconButton
                          onClick={() => onDeleteStudent(row)}
                          data-testid="student-delete-button"
                          aria-label="Delete student">
                          <DeleteIcon role="img" aria-label="Delete Icon" focusable="false" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                </TableRow>
              )
            }
          })}
        </TableBody>
      </Table>
      {sortedStudents.length === 0 && !showAddStudentForm && (
        <Typography variant="body" data-testid="no-students-notice" className={styles.noStudentsInfo}>
          No students yet{canEdit && ` – use the controls above to add or import`}
        </Typography>
      )}
    </Card>
  )
}

export default StudentsTable
