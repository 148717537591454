import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AlertContext, Button, Select, TextInput, Typography, ValidationRules, countries } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { Formik, FormikHelpers } from 'formik'
import { ROUTES } from 'routes/routes'
import { printApiMessage } from 'shared/api/apiMessages'
import * as Yup from 'yup'

import { createProfile } from 'store/services/Auth/authReducer'
import { TeacherData, TeacherProfile } from 'store/services/Auth/types'

import styles from './CreateProfile.module.scss'

const validationSchema = Yup.object().shape({
  firstName: ValidationRules.required,
  lastName: ValidationRules.required,
  school: ValidationRules.required,
  country: ValidationRules.required,
  city: ValidationRules.required
})

interface FormFields extends Omit<TeacherProfile, 'avatar'> {}

const CreateProfile = () => {
  const { showAlert } = useContext(AlertContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const countryOptions = useMemo(() => countries.map(({ code, name }) => ({ label: name, value: code })), [])

  const handleSubmit = useCallback(
    async (formValues: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      try {
        const user = await dispatch(createProfile({ ...formValues }))
        if ((user as unknown as TeacherData)?.tutorialCompleted) {
          history.push(`${ROUTES.CLASSES}`)
        }
      } catch ({ message }) {
        showAlert(printApiMessage(message))
        setSubmitting(false)
      }
    },
    [dispatch, history, showAlert]
  )

  return (
    <AuthWrapper>
      <Typography variant="h2" component="h1">
        Create a profile
      </Typography>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          school: '',
          country: '',
          city: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} id="create-profile-form">
              <TextInput
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange('firstName')}
                error={touched.firstName && !!errors.firstName}
                helperText={(touched.firstName && errors.firstName) || ''}
                onBlur={() => setFieldTouched('firstName')}
              />
              <TextInput
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange('lastName')}
                error={touched.lastName && !!errors.lastName}
                helperText={(touched.lastName && errors.lastName) || ''}
                onBlur={() => setFieldTouched('lastName')}
              />
              <TextInput
                name="school"
                label="School name"
                value={values.school}
                onChange={handleChange('school')}
                error={touched.school && !!errors.school}
                helperText={(touched.school && errors.school) || ''}
                onBlur={() => setFieldTouched('school')}
              />
              <div className={styles.wrapper}>
                <Select
                  label="Country"
                  name="country"
                  className={styles.select}
                  value={values.country}
                  options={countryOptions}
                  onChange={handleChange('country')}
                  testId="country-select"
                  error={touched.country && !!errors.country}
                  onBlur={() => setFieldTouched('country')}
                  helperText={(touched.country && errors.country) || ''}
                />
                <TextInput
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange('city')}
                  error={touched.city && !!errors.city}
                  helperText={(touched.city && errors.city) || ''}
                  onBlur={() => setFieldTouched('city')}
                />
              </div>
              <Button type="submit" color="black" variant="flat" disabled={isSubmitting}>
                Create
              </Button>
            </form>
          )
        }}
      </Formik>
    </AuthWrapper>
  )
}

export default CreateProfile
