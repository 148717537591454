import React from 'react'

import { ComprehensionChart, ComprehensionData } from 'shared/components/Charts/ComprehensionChart'
import { FluencyChart, FluencyData } from 'shared/components/Charts/FluencyChart'

import { Progress } from 'store/services/Overview/types'

import styles from './OverviewSections.module.scss'
import ProgressSegment from './ProgressSegment'

interface ProgressSectionProps {
  progress: Progress
  analytics: {
    fluency: FluencyData
    fluencyGoal: number | undefined
    comprehension: ComprehensionData
  }
  displayAverage?: boolean
}

const ProgressSection = ({ progress, analytics, displayAverage }: ProgressSectionProps) => {
  return (
    <div className={styles.progressIndicators}>
      <div className={styles.chartSection}>
        <ProgressSegment
          data={progress.fluency}
          progressColor="#22A2B4"
          size="regular"
          title={
            <>
              Fluency<span>(words per min)</span>
            </>
          }
          scoreSubtitle={displayAverage ? 'avg.' : ''}
        />
        <div className={styles.progressChart}>
          <FluencyChart data={analytics.fluency} chartType={'bar'} />
        </div>
      </div>

      <div className={styles.chartSection}>
        <ProgressSegment
          data={progress.comprehension}
          progressColor="#22A2B4"
          size="regular"
          title={
            <>
              Comprehension<span>(percentage)</span>
            </>
          }
          scoreSubtitle={displayAverage ? 'avg.' : ''}
        />
        <div className={styles.progressChart}>
          <ComprehensionChart data={analytics.comprehension} chartType={'bar'} />
        </div>
      </div>
    </div>
  )
}

export default ProgressSection
