import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AlertContext, CheckmarkIcon, CloseIcon, EditIcon, TextInput, Typography } from '@astrid/components'
import { IconButton, Tooltip } from '@material-ui/core'

import { updateCurrentClass } from 'store/services/Classes/reducer'

import styles from '../ClassView.module.scss'
import { ClassViewMenu } from './ClassViewMenu'

interface ClassViewHeaderProps {
  name: string
  canEdit: boolean
  classId: string
  onDeleteClass: () => void
}

interface ClassViewHeaderState {
  editName: boolean
  nameValue: string
  nameError: string
}

const ConfirmationButtons = ({ confirm, cancel }: { confirm: () => void; cancel: () => void }) => {
  return (
    <div className={styles.header__confirmationButtons}>
      <Tooltip interactive title="Save">
        <IconButton onClick={confirm} aria-label="Save">
          <CheckmarkIcon role="img" aria-label="Checkmark Icon" focusable="false" />
        </IconButton>
      </Tooltip>
      <Tooltip interactive title="Cancel">
        <IconButton onClick={cancel} aria-label="Cancel">
          <CloseIcon role="img" aria-label="Close Icon" focusable="false" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const ClassViewHeader = ({ name, canEdit, classId, onDeleteClass }: ClassViewHeaderProps) => {
  const dispatch = useDispatch()
  const { showAlert } = useContext(AlertContext)
  const [state, setState] = useState<ClassViewHeaderState>({
    editName: false,
    nameValue: name,
    nameError: ''
  })

  const handleNameChange = useCallback((value: string) => {
    setState((s) => ({
      ...s,
      nameValue: value
    }))
  }, [])

  const handleCancelNameChange = useCallback(() => {
    setState((s) => ({
      ...s,
      editName: false,
      nameValue: name,
      nameError: ''
    }))
  }, [name])

  const handleSubmitNameChange = useCallback(async () => {
    if (state.nameValue.length > 50) {
      setState((s) => ({ ...s, nameError: 'Must be less than 50 characters.' }))
      return
    }
    if (state.nameValue === '') {
      setState((s) => ({ ...s, nameError: 'Required' }))
      return
    }
    try {
      await dispatch(updateCurrentClass(classId, { name: state.nameValue }))
      setState((s) => ({
        ...s,
        editName: false,
        nameError: ''
      }))
    } catch (error) {
      showAlert('Something went wrong, please try again.')
    }
  }, [classId, state, dispatch, showAlert])

  return (
    <div className={styles.header}>
      <div className={styles.header__block}>
        {state.editName ? (
          <>
            <TextInput
              label="Class name"
              name="nameValue"
              value={state.nameValue}
              onChange={(event) => handleNameChange(event.currentTarget.value)}
              error={!!state.nameError}
              helperText={state.nameError}
            />
            <ConfirmationButtons cancel={handleCancelNameChange} confirm={handleSubmitNameChange} />
          </>
        ) : (
          <>
            <Typography variant="h3">{name}</Typography>
            <Tooltip title={canEdit ? '' : 'Cannot edit: this class is managed externally'}>
              <span>
                <button
                  disabled={!canEdit}
                  className={styles.header__block__edit}
                  onClick={() => setState((s) => ({ ...s, editName: true }))}
                  aria-label="Edit class name">
                  <EditIcon />
                </button>
              </span>
            </Tooltip>
          </>
        )}
      </div>
      <div className={styles.header__block}>
        <ClassViewMenu onDelete={onDeleteClass} />
      </div>
    </div>
  )
}

export default ClassViewHeader
