export const GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME = 'handleRecaptchaCheck'

export const appConfig = {
  environment: process.env.NODE_ENV,
  domain: process.env.REACT_APP_DOMAIN,
  apiUrl: process.env.REACT_APP_API_URL,
  publicUrl: process.env.PUBLIC_URL,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  hotjarId: process.env.REACT_APP_HOTJAR_ID,
  googleTagManagerId: process.env.REACT_APP_GTM_ID,
  sentry: {
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE
  }
} as const
