import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Typography, useWindowSize } from '@astrid/components'
import rightGraphic from '@astrid/components/dist/graphics/abby_jumping.png'
import leftGraphic from '@astrid/components/dist/graphics/welcome-left.png'
import { ROUTES } from 'routes/routes'
import { ReactComponent as ArrowRight } from 'shared/assets/arrow-right.svg'

import { selectUserSettings, setTutorialCompleted } from 'store/services/Auth/authReducer'

import styles from './Tutorial.module.scss'

const Tutorial = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const settings = useSelector(selectUserSettings)
  const { width } = useWindowSize()

  const onFinish = async () => {
    await dispatch(setTutorialCompleted())
    history.push(ROUTES.LIBRARY)
  }

  return (
    <div className={styles.container}>
      {settings.introContent ? (
        <>
          <Typography variant="h2">{settings.introContent?.title}</Typography>

          <Typography variant="body" color="secondary">
            Watch our introduction video to learn more!
          </Typography>

          <iframe
            title="onboarding_video"
            style={{
              width: (width ?? 0) <= 1450 ? 816 : 921,
              height: (width ?? 0) <= 1450 ? 459 : 518,
              marginTop: 10,
              border: 'none',
              zIndex: 2
            }}
            src={settings.introContent.videoUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </>
      ) : null}
      <Button className={styles.button} color="black" variant="flat" onClick={onFinish}>
        Get started
        <ArrowRight />
      </Button>
      <img className={styles.leftGraphic} src={leftGraphic} alt="characters-left" />
      <img className={styles.rightGraphic} src={rightGraphic} alt="characters-right" />
    </div>
  )
}

export default Tutorial
