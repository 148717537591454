import * as React from 'react'

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

export type EffortData = Array<{
  day: string
  minutes: number | null
  exercisesCompleted?: number
  storiesCompleted?: number
}>

const separateChartProps = {
  margin: {
    top: 15,
    left: 5,
    right: 0
  }
}

export const EffortSingleChart = ({
  data,
  effortName = 'minutes',
  defaultMaxY = 60
}: {
  data: EffortData
  effortName: string
  defaultMaxY: number
}) => {
  const yAxisProps = { domain: [0, defaultMaxY] }
  return (
    <BarChart width={300} height={300} data={data} {...separateChartProps}>
      <CartesianGrid vertical={false} />
      <XAxis tickLine={false} axisLine={false} dataKey="day" stroke={'#c8c8c8'} />
      <YAxis tickLine={false} axisLine={false} width={40} stroke={'#c8c8c8'} {...yAxisProps} />
      <Bar dataKey={effortName} fill="#B30026" />
    </BarChart>
  )
}
