import React from 'react'
import { AutoSizer, Grid, GridCellRenderer } from 'react-virtualized'

import { Typography } from '@astrid/components'
import { ReactComponent as BookIcon } from 'shared/assets/book.svg'

import styles from './LibraryViewSection.module.scss'

type Props = {
  columnCount: number
  label: string
  cellRenderer: GridCellRenderer
}

function LibraryViewSection({ columnCount, label, cellRenderer }: Props) {
  return (
    <div className={styles.difficultySection}>
      <span className={styles.difficulty}>
        <BookIcon role="img" aria-label="Book Icon" focusable="false" />
        <Typography variant="h3">{label}</Typography>
      </span>

      <div className={styles.carouselContainer}>
        <AutoSizer defaultWidth={1000} defaultHeight={420}>
          {({ width, height }) => (
            <Grid
              width={width || 1000}
              height={height || 420}
              rowCount={1}
              columnCount={columnCount}
              rowHeight={300}
              columnWidth={280}
              cellRenderer={cellRenderer}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

export default LibraryViewSection
