import { HttpErrors } from '@astrid/shared'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'

import AuthApi from 'store/services/Auth/authApi'

interface ApiServiceConfig {
  API_URL?: string
  DEFAULT_HTTP_HEADERS: { [key: string]: string }
  ACCESS_TOKEN_KEY: string
  GET_ACCESS_TOKEN: () => string | null
}

// Error interceptor predicates for when to just return early, avoiding automatic logout & message mangling:
const HANDLED_ERROR_API_PATHS: Array<(err: AxiosError) => boolean> = [
  (err) => !!err.config.url && err.config.url?.startsWith('/auth/reset-pass')
]

const LOGOUT_MESSAGES = [HttpErrors.AccountDisabled]
const LOGOUT_STATUS_CODES = [401]

class ApiService {
  private _service!: AxiosInstance

  constructor({ API_URL, DEFAULT_HTTP_HEADERS, ACCESS_TOKEN_KEY, GET_ACCESS_TOKEN }: ApiServiceConfig) {
    if (!API_URL) {
      throw new Error('API_URL is required')
    }

    if (!ACCESS_TOKEN_KEY) {
      throw new Error('ACCESS_TOKEN_KEY is required')
    }

    const service = axios.create({
      baseURL: API_URL,
      validateStatus: (status) => status >= 200 && status < 300,
      headers: DEFAULT_HTTP_HEADERS || { 'Content-Type': 'application/json' }
    })

    // Add access token to every request if it exist
    service.interceptors.request.use(
      (config) => {
        const accessToken = GET_ACCESS_TOKEN()

        if (accessToken) {
          config.headers = { ...config.headers, [ACCESS_TOKEN_KEY]: `${accessToken}` }
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    // Add global handlers for success and error response
    service.interceptors.response.use(this.handleSuccess, (err) => Promise.reject(this.handleError(err)))
    this.service = service
  }

  handleSuccess(response: AxiosResponse): AxiosResponse {
    return response
  }

  handleError(error: AxiosError<{ error: any }>): AxiosError | string | object {
    if (!error) {
      return 'Something went wrong... Please try again.'
    }

    // While the `error` is an AxiosError here, in reality the interceptor error arg is of `any` type:
    if (axios.isAxiosError(error) && HANDLED_ERROR_API_PATHS.some((p) => p(error))) {
      return error
    }

    const errorMessage = error.response?.data?.error || error.message
    const shouldLogOutUser =
      (errorMessage && LOGOUT_MESSAGES.includes(errorMessage)) ||
      (error.response?.status && LOGOUT_STATUS_CODES.includes(error.response?.status))

    if (shouldLogOutUser) {
      AuthApi.logout()
      return errorMessage
    }

    return errorMessage || error
  }

  get service(): AxiosInstance {
    return this._service
  }

  set service(service: AxiosInstance) {
    this._service = service
  }
}

export default ApiService
