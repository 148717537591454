import React from 'react'

import { EffortData, EffortSingleChart } from 'shared/components/Charts/EffortSingleChart'

import { Effort } from 'store/services/Overview/types'

import styles from './OverviewSections.module.scss'
import ProgressSegment from './ProgressSegment'

interface EffortSectionProps {
  effort: Effort
  analytics: {
    data: EffortData
    storiesGoal: number | undefined
    exercisesGoal: number | undefined
  }
}

const EffortSection = ({ effort, analytics }: EffortSectionProps) => {
  return (
    <div className={styles.progressIndicators}>
      <div className={styles.chartSection}>
        <ProgressSegment
          data={effort.timeSpent}
          progressColor="#B30026"
          size="regular"
          title={
            <>
              Time studied<span>(min)</span>
            </>
          }
        />
        <div className={styles.effortChart}>
          <EffortSingleChart data={analytics.data} effortName="minutes" defaultMaxY={60} />
        </div>
      </div>

      <div className={styles.chartSection}>
        <ProgressSegment
          data={effort.booksCompleted}
          progressColor="#B30026"
          size="regular"
          title="Speaking exercises (count)"
        />

        <div className={styles.effortChart}>
          <EffortSingleChart data={analytics.data} effortName="storiesCompleted" defaultMaxY={10} />
        </div>
      </div>
    </div>
  )
}

export default EffortSection
