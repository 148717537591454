import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import ActivateEmail from 'Auth/ActivateEmail/ActivateEmail'
import Login from 'Auth/Login/Login'
import ResetPassword from 'Auth/ResetPassword/ResetPassword'
import VerifyEmail from 'Auth/VerifyEmail/VerifyEmail'
import BookView from 'Book/BookView'
import ClassOverview from 'ClassOverview/ClassOverview'
import ClassView from 'Classes/ClassView/ClassView'
import Classes from 'Classes/Classes'
import CreateClass from 'Classes/CreateClass/CreateClass'
import Dashboard from 'Dashboard/Dashboard'
import Insights from 'Insights/Insights'
import LibraryView from 'Library/LibraryView'
import Profile from 'Profile/Profile'
import StudentOverview from 'StudentOverview/StudentOverview'
import { Analytics } from 'analytics/analyticsApi'
import AuthWall from 'routes/AuthWall/AuthWall'
import { ROUTES } from 'routes/routes'
import { appConfig } from 'shared/appConfig'
import Header from 'shared/components/Header/Header'
import RecoverPassword from 'shared/components/RecoverPassword/RecoverPassword'
import Theme from 'shared/components/Theme/Theme'

import { selectBranding, selectUser } from 'store/services/Auth/authReducer'

import EmailWall from './EmailWall/EmailWall'

const RouteStack = () => {
  const user = useSelector(selectUser)
  const branding = useSelector(selectBranding)
  const userId = user?._id

  React.useEffect(() => {
    if (typeof userId !== 'string') return
    Analytics.identify(userId, {
      /* only identify to pass client-specifics, the rest was sent from BE already */
    })
    // We only want to run this if the user id has changed and not when the user object changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <Theme brand={branding}>
      <Router basename={appConfig.publicUrl}>
        <AuthWall authStack={<AuthStack />}>
          <Header />
          <Dashboard>
            <Switch>
              <Route exact path={ROUTES.INSIGHTS} component={Insights} />
              <Route exact path={`${ROUTES.STUDENT}/:classId/:id`} component={StudentOverview} />
              <Route exact path={`${ROUTES.CLASS}/:classId`} component={ClassOverview} />
              <Route exact path={`${ROUTES.BOOK}/:bookContext/:id`} component={BookView} />
              <Route exact path={`${ROUTES.LIBRARY}`} component={LibraryView} />
              <Route exact path={`${ROUTES.CLASSES}/:id`} component={ClassView} />
              <Route exact path={`${ROUTES.CLASSES}`} component={Classes} />
              <Route exact path={`${ROUTES.CLASSES_CREATE}`} component={CreateClass} />
              <Route exact path={ROUTES.PROFILE} component={Profile} />
              <Redirect to={ROUTES.INSIGHTS} />
            </Switch>
          </Dashboard>
        </AuthWall>
      </Router>
    </Theme>
  )
}

const AuthStack = () => {
  return (
    <EmailWall>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={Login} />
        <Route path={`${ROUTES.ACTIVATE_EMAIL}/:email`} component={ActivateEmail} />
        <Route path={`${ROUTES.VERIFY_EMAIL}/:token`} component={VerifyEmail} />
        <Route exact path={`${ROUTES.REQUEST_PASSWORD_RESET}`} component={RecoverPassword} />
        <Route path={`${ROUTES.RESET_PASSWORD}`} component={ResetPassword} />
        <Redirect to={ROUTES.ROOT} />
      </Switch>
    </EmailWall>
  )
}

export default RouteStack
