import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { AlertContext } from '@astrid/components'
import CreateProfile from 'Auth/CreateProfile/CreateProfile'
import Tutorial from 'Tutorial/Tutorial'
import { printApiMessage } from 'shared/api/apiMessages'
import { appConfig } from 'shared/appConfig'
import PageLoader from 'shared/components/PageLoader/PageLoader'
import { checkIsUnauthorized } from 'shared/helpers/utils'

import { useLoginResult } from 'hooks/useLoginResult'
import AuthApi from 'store/services/Auth/authApi'
import { getUser, selectUser, selectUserSettings } from 'store/services/Auth/authReducer'
import { VerificationStatus } from 'store/services/Auth/types'
import { ThunkDispatch } from 'store/types'

interface Props {
  authStack: React.ReactElement
}

const AuthWall = ({ children, authStack }: PropsWithChildren<Props>) => {
  const { showAlert } = useContext(AlertContext)
  const dispatch = useDispatch<ThunkDispatch>()
  const user = useSelector(selectUser)
  const settings = useSelector(selectUserSettings)
  const { loginResult } = useLoginResult()
  const [loading, setLoading] = useState(true)

  const matchEmailChange = useRouteMatch<{ token: string }>({
    path: '/auth/verify-change/:token',
    strict: true,
    exact: true
  })

  const changedEmailVerificationToken = matchEmailChange?.params.token

  // verify email change
  useEffect(() => {
    if (changedEmailVerificationToken) {
      try {
        AuthApi.verifyEmailChange(changedEmailVerificationToken)
      } catch (error) {
        showAlert(printApiMessage('Failed to verify email'))
      }
    }
  }, [changedEmailVerificationToken, showAlert])

  // fetch user data
  useEffect(() => {
    let isCancelled = false

    const fetchUser = async () => {
      try {
        if (AuthApi.getUserToken()) {
          await dispatch(getUser())
        }
      } catch (error) {
        if (!checkIsUnauthorized(error) && !isCancelled) {
          showAlert(printApiMessage('Failed to get user data'))
        }
      } finally {
        !isCancelled && setLoading(false)
      }
    }

    fetchUser()

    return () => {
      isCancelled = true
    }
  }, [dispatch, showAlert])

  // show alert for small screens
  useEffect(() => {
    const shouldShowUnsupportedMessage =
      window.innerWidth < 900 && appConfig.environment !== 'test' && !AuthApi.getUserToken()

    if (shouldShowUnsupportedMessage && !loginResult) {
      alert(
        'The website is not yet optimized for mobile devices and it will not work correctly on them. Please use the application on desktop devices.'
      )
    }
  }, [loginResult])

  const loggedIn = user !== undefined
  const emailConfirmed = user?.verificationStatus === VerificationStatus.VERIFIED
  const hasProfile = user?.profile

  if (loading) {
    return <PageLoader />
  }

  if (loggedIn && !hasProfile) {
    return <CreateProfile />
  }

  if (!loggedIn || !emailConfirmed) {
    return authStack
  }

  if (loggedIn && !user?.tutorialCompleted && settings.introContent) {
    return <Tutorial />
  }

  return <>{children}</>
}

export default AuthWall
