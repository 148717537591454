import React from 'react'

import { Button } from '@astrid/components'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

type Props = {
  open: boolean
  className: string
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmDeleteClassDialog = ({ open, className, onConfirm, onCancel }: Props) => (
  <Dialog
    open={open}
    onClose={onCancel}
    role="alertdialog"
    aria-labelledby="confirm-delete-class-dialog-title"
    aria-describedby="confirm-delete-class-dialog-description">
    <DialogTitle id="confirm-delete-class-dialog-title">Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText id="confirm-delete-class-dialog-description">
        <p>This will permanently remove "{className}" from your class list.</p>
        <p>Note that this action cannot be undone.</p>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="filled" onClick={onConfirm} autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)
