import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { ToggleSwitch, Typography } from '@astrid/components'
import { trackButtonClick } from 'analytics/analytics'
import { ROUTES } from 'routes/routes'

import { getClasses, updateCurrentClass } from 'store/services/Classes/reducer'
import { EnglishDialect, StudentClass } from 'store/services/Classes/types'
import { localStorageService } from 'utils/localStorage'

import styles from './ClassesList.module.scss'

interface ClassesListProps {
  classes: StudentClass[]
}

const ClassesList = ({ classes }: ClassesListProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th>
              <Typography variant="h4">Name</Typography>
            </th>
            <th>
              <Typography variant="h4">Students</Typography>
            </th>
            <th>
              <Typography variant="h4">American/British English</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {classes.map((studentClass) => (
            <tr className={styles.row} key={studentClass._id}>
              <td>
                <Link
                  to={`${ROUTES.CLASSES}/${studentClass._id}`}
                  onClick={() => trackButtonClick({ componentName: 'Class name', componentValue: studentClass.name })}>
                  {studentClass.name}
                </Link>
              </td>
              <td>{studentClass.users.length}</td>
              <td>
                <div className={styles.toggleWrapper}>
                  <ToggleSwitch
                    className={styles.toggle}
                    style={isLoading ? { pointerEvents: 'none', opacity: 0.5 } : undefined}
                    name={`${studentClass._id}__englishDialect`}
                    falseLabel={`American English`}
                    falseLabelId={`${studentClass._id}__American English`}
                    trueLabel={`British English`}
                    trueLabelId={`${studentClass._id}__British English`}
                    value={studentClass.englishDialect === EnglishDialect.EnGB}
                    onChange={async () => {
                      if (isLoading) return
                      try {
                        setIsLoading(true)
                        const nextValue =
                          studentClass.englishDialect === EnglishDialect.EnGB
                            ? EnglishDialect.EnUS
                            : EnglishDialect.EnGB
                        await dispatch(updateCurrentClass(studentClass._id, { englishDialect: nextValue }))
                        await dispatch(getClasses())
                      } catch (error) {
                      } finally {
                        setIsLoading(false)
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ClassesList
