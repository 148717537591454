import React, { PropsWithChildren } from 'react'

import { Branding } from 'store/services/Auth/types'

import './Theme.scss'

interface Props {
  brand: Branding
}

const Theme = ({ brand, children }: PropsWithChildren<Props>) => {
  return <div className={`brand-${brand} theme`}>{children}</div>
}

export default Theme
