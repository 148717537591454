import { WebEventing } from '@astrid/eventing'

export class Analytics {
  static identify(_userId: string, _traits: object) {
    // analytics.identify(userId, traits)
  }

  static page() {
    // analytics.page()
  }

  static track(_event: WebEventing.Event<object>) {
    // analytics.track(event.name, event.props)
  }
}
