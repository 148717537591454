import React, { Component } from 'react'

import { CheckmarkIcon, CloseIcon, ReplayIcon, Typography, ValidationRules } from '@astrid/components'
import { IconButton, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'
import * as Yup from 'yup'

import styles from './StudentFormRow.module.scss'

export interface StudentFormRowValues {
  firstName: string
  lastName: string
  username?: string
  password?: string
}

type TouchedFields = {
  [key in keyof StudentFormRowValues]?: boolean
}

export const StudentFormRowValidationSchema = Yup.object().shape({
  firstName: ValidationRules.name.required('Required'),
  lastName: ValidationRules.name.required('Required')
})

interface StudentFormRowProps {
  values: StudentFormRowValues
  fieldArrayName?: string
  touched?: TouchedFields
  errors?: Partial<StudentFormRowValues>
  withConfirmButton?: boolean
  validateWithoutTouch?: boolean
  cancelTooltip?: string
  hideCredentialsPlaceholders?: boolean
  canRegeneratePassword?: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  onCancel: () => void
  onRegeneratePassword?: () => Promise<void>
  handleConfirm?: () => void
}

class StudentFormRow extends Component<StudentFormRowProps> {
  shouldComponentUpdate(nextProps: StudentFormRowProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) return true
    return false
  }

  private displayError = (name: keyof StudentFormRowValues): string | undefined => {
    const { touched, errors } = this.props
    if (errors) {
      if (this.props.validateWithoutTouch) {
        return errors[name]
      } else if (touched) {
        return touched[name] && errors[name] ? errors[name] : undefined
      }
      return undefined
    }
    return undefined
  }

  render() {
    const {
      canRegeneratePassword,
      fieldArrayName,
      values,
      handleChange,
      handleBlur,
      onRegeneratePassword,
      cancelTooltip,
      onCancel,
      withConfirmButton,
      handleConfirm
    } = this.props
    return (
      <TableRow className={styles.row}>
        <TableCell>
          <TextField
            type="text"
            id={fieldArrayName ? `${fieldArrayName}_firstName_input` : 'student_firstName_input'}
            name={fieldArrayName ? `${fieldArrayName}.firstName` : 'firstName'}
            label="First name"
            required
            size="small"
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
            error={!!this.displayError('firstName')}
            helperText={this.displayError('firstName')}
            onBlur={handleBlur}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            id={fieldArrayName ? `${fieldArrayName}_lastName_input` : 'student_lastName_input'}
            name={fieldArrayName ? `${fieldArrayName}.lastName` : 'lastName'}
            label="Last name"
            required
            size="small"
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            error={!!this.displayError('lastName')}
            helperText={this.displayError('lastName')}
            onBlur={handleBlur}
          />
        </TableCell>
        {!this.props.hideCredentialsPlaceholders && (
          <>
            <TableCell data-testid="username">
              <Typography variant="label" data-testid="username-label">
                {values.username}
              </Typography>
            </TableCell>
            <TableCell data-testid="password">
              <Typography variant="label" data-testid="password-label">
                {values.password}
              </Typography>
              {canRegeneratePassword ? (
                <Tooltip title="Regenerate password">
                  <IconButton onClick={onRegeneratePassword} aria-label="Regenerate password">
                    <ReplayIcon role="img" aria-label="Regenerate password icon" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </TableCell>
          </>
        )}
        <TableCell align="right">
          {withConfirmButton && (
            <Tooltip interactive title="Save">
              <IconButton
                type="submit"
                onClick={handleConfirm}
                data-testid="student-add-confirm-button"
                aria-label="Save">
                <CheckmarkIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip interactive title={cancelTooltip || 'Cancel'}>
            <IconButton onClick={onCancel} aria-label="Cancel">
              <CloseIcon role="img" aria-label="Close Icon" focusable="false" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }
}

export default StudentFormRow
