import React, { useMemo } from 'react'

import { ValidationRules } from '@astrid/components'
import { Formik } from 'formik'
import StudentFormRow from 'shared/components/StudentFormRow/StudentFormRow'
import * as Yup from 'yup'

export interface SingleStudentFormValues {
  firstName: string
  lastName: string
  username?: string
  password?: string
}

export interface StudentFormRowProps {
  onCancel: () => void
  onSubmit: (values: SingleStudentFormValues) => void
  onRegeneratePassword?: () => Promise<string | null>
  initialValues?: Partial<SingleStudentFormValues>
  canRegeneratePassword?: boolean
}

const SingleStudentForm = ({
  onCancel,
  onRegeneratePassword,
  onSubmit,
  initialValues,
  canRegeneratePassword
}: StudentFormRowProps) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: ValidationRules.name.required('Required'),
        lastName: ValidationRules.name.required('Required')
      }),
    []
  )

  return (
    <Formik
      initialValues={{
        firstName: initialValues?.firstName || '',
        lastName: initialValues?.lastName || '',
        username: initialValues?.username || '',
        password: initialValues?.password || ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ values, handleChange, errors, touched, handleSubmit, handleBlur, setFieldValue }) => {
        const handleRegeneratePassword = async () => {
          if (!onRegeneratePassword) return
          const newPassword = await onRegeneratePassword()
          if (newPassword) await setFieldValue('password', newPassword)
        }
        return (
          <StudentFormRow
            handleBlur={handleBlur}
            handleChange={handleChange}
            onCancel={onCancel}
            onRegeneratePassword={handleRegeneratePassword}
            values={values}
            canRegeneratePassword={canRegeneratePassword}
            errors={errors}
            handleConfirm={handleSubmit}
            touched={touched}
            withConfirmButton
          />
        )
      }}
    </Formik>
  )
}

export default SingleStudentForm
