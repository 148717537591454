import React from 'react'
import { useSelector } from 'react-redux'

import { Card, ChipsFactory } from '@astrid/components'

import {
  formatComprehensionAnalytics,
  formatEffortAnalytics,
  formatFluencyAnalytics,
  selectTimePeriod
} from 'store/services/Overview/reducer'
import { StatsOverview } from 'store/services/Overview/types'

import styles from './Overview.module.scss'
import EffortSection from './Sections/EffortSection'
import ProgressSection from './Sections/ProgressSection'

interface Props {
  overview: StatsOverview
  className?: string
  style?: React.CSSProperties
  displayMonth?: boolean
  showChallengingWordsToPronounce?: boolean
  showChallengingWordsToSpell?: boolean
  showChallengingSounds?: boolean
  showTrending?: boolean
  showOnlyTimeEffort?: boolean
  progressSubtitle?: string
  effortSubtitle?: string
}

const Overview = ({
  overview,
  className,
  style,
  displayMonth,
  progressSubtitle,
  effortSubtitle,
  showChallengingWordsToPronounce = true,
  showChallengingWordsToSpell = true,
  showChallengingSounds = true,
  showTrending = true
}: Props) => {
  const timePeriod = useSelector(selectTimePeriod)

  if (!overview.stats || !overview.words) return null
  const {
    stats: { effort, progress },
    words: { challengingSounds, challengingWordsToPronounce, challengingWordsToSpell, trendingHashtags }
  } = overview

  return (
    <section className={`${styles.container} ${className ? className : ''}`} style={style}>
      <div data-side="left">
        <Card title="Progress" titleNote={progressSubtitle ?? 'Average'} className={styles.card}>
          <ProgressSection
            progress={progress}
            analytics={{
              fluency: formatFluencyAnalytics(overview.analytics?.progress.fluency.userScore, timePeriod),
              fluencyGoal: !displayMonth ? progress.fluency.userGoal : undefined,
              comprehension: formatComprehensionAnalytics(
                overview.analytics?.progress.comprehension.userScore,
                timePeriod
              )
            }}
            displayAverage={displayMonth}
          />
        </Card>
        {showTrending && (
          <Card title="Trending" className={styles.card}>
            <ChipsFactory data={trendingHashtags} textKey="name" textModifier={(text) => `#${text}`} />
          </Card>
        )}
      </div>
      <div data-side="right">
        <Card title="Efforts" titleNote={effortSubtitle ?? 'Total'} className={styles.card}>
          <EffortSection
            effort={effort}
            analytics={{
              data: formatEffortAnalytics(overview.analytics?.effort, timePeriod),
              storiesGoal: !displayMonth ? effort.booksCompleted.userGoal : undefined,
              exercisesGoal: !displayMonth ? effort.exercisesCompleted.userGoal : undefined
            }}
          />
        </Card>
        {showChallengingWordsToPronounce && (
          <Card title="Challenging Words to Pronounce" className={styles.card}>
            <ChipsFactory data={challengingWordsToPronounce} sortKey="avgScore" textKey="name" />
          </Card>
        )}
        {showChallengingSounds && (
          <Card title="Challenging Sounds" className={styles.card}>
            <ChipsFactory data={challengingSounds} sortKey="avgScore" textKey="name" />
          </Card>
        )}
        {showChallengingWordsToSpell && (
          <Card title="Challenging Words to Spell" className={styles.card}>
            <ChipsFactory data={challengingWordsToSpell} sortKey="avgScore" textKey="name" />
          </Card>
        )}
      </div>
    </section>
  )
}

export default Overview
