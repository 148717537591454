import { SingleStudentFormValues } from 'Classes/StudentsTable/components/SingleStudentForm'
import { StudentFormRowValues } from 'shared/components/StudentFormRow/StudentFormRow'

import ClassesApi from './api'
import { CreateStudentData, Student, StudentsTableRow } from './types'

export const checkIfUsernameAvailable = async (value: string | null | undefined): Promise<boolean> => {
  if (value) {
    try {
      const {
        data: { available }
      } = await ClassesApi.checkUsernameAvailability(value)
      return available
    } catch (error) {
      return true
    }
  }
  return true
}

export const formatStudentDataForApi = (values: SingleStudentFormValues | StudentFormRowValues): CreateStudentData => {
  const { firstName, lastName, username, password } = values as StudentFormRowValues
  return {
    firstName,
    lastName,
    ...(username && username !== '' && { username }),
    ...(password && password !== '' && { password })
  }
}

export const transformPreviewStudentsFromApiToForm = (
  studentsPreviewFromApi: CreateStudentData[]
): StudentFormRowValues[] => {
  return studentsPreviewFromApi.map((student) => ({
    firstName: student.firstName || '',
    lastName: student.lastName || '',
    username: student.username || '',
    password: student.password || ''
  }))
}

export const transformStudentsFromApiToTable = (studentsFromApi: Student[]): StudentsTableRow[] => {
  return studentsFromApi.map((student) => ({
    firstName: student.profile.firstName,
    lastName: student.profile.lastName,
    username: student.username,
    password: student.password,
    _id: student._id
  }))
}
