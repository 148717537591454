import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, Typography } from '@astrid/components'
import { ScreenNames } from 'analytics/analytics'
import { ReactComponent as GoogleClassroomLogo } from 'shared/assets/google-classroom.svg'
import { ReactComponent as CirclePlusIcon } from 'shared/assets/plus-circle.svg'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { selectUserSettings } from 'store/services/Auth/authReducer'
import { getClasses, selectClasses } from 'store/services/Classes/reducer'

import styles from './Classes.module.scss'
import ClassesList from './ClassesList/ClassesList'
import CreateClass from './CreateClass/CreateClass'
import GoogleClassroom from './GoogleClassroom/GoogleClassroom'

const Classes = () => {
  useTrackScreenShown(ScreenNames.MyClasses)
  const classes = useSelector(selectClasses)
  const dispatch = useDispatch()
  const userSettings = useSelector(selectUserSettings)
  const [addClassModalOpen, setAddClassModalOpen] = useState(false)
  const [addGoogleClassroomModalOpen, setAddGoogleClassroomModalOpen] = useState(false)

  useEffect(() => {
    try {
      dispatch(getClasses())
    } catch (error) {}
  }, [dispatch])

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h2">My Classes</Typography>
        <div className={styles.actions}>
          {userSettings.googleClassroomFeatures ? (
            <Button
              color="primary"
              variant="flat"
              onClick={() => setAddGoogleClassroomModalOpen(true)}
              className={styles.add_btn}>
              <GoogleClassroomLogo aria-hidden="true" focusable="false" /> Import from Google Classroom
            </Button>
          ) : null}

          {userSettings.allowClassManagement ? (
            <Button color="black" variant="flat" onClick={() => setAddClassModalOpen(true)} className={styles.add_btn}>
              <CirclePlusIcon aria-hidden="true" focusable="false" /> Create new class
            </Button>
          ) : null}
        </div>
      </div>
      {classes.length > 0 ? (
        <ClassesList classes={classes} />
      ) : (
        <Typography variant="exerciseS">
          Hi! This is where you add students to your class. Start by clicking "Create New Class" or import from Google
          Classroom.
        </Typography>
      )}
      <Modal open={addClassModalOpen} closeModal={() => setAddClassModalOpen(false)} hideCloseButton variant="teacher">
        <CreateClass onCancel={() => setAddClassModalOpen(false)} />
      </Modal>
      <Modal
        open={addGoogleClassroomModalOpen}
        closeModal={() => setAddGoogleClassroomModalOpen(false)}
        hideCloseButton
        variant="teacher">
        <GoogleClassroom onCancel={() => setAddGoogleClassroomModalOpen(false)} />
      </Modal>
    </section>
  )
}

export default Classes
