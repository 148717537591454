import React from 'react'
import { connect } from 'react-redux'

import { ArrowRight, Book, BookContext, ExerciseTransition, IExercise } from '@astrid/components'
import { appConfig } from 'shared/appConfig'

import { selectUserCountry } from 'store/services/Auth/authReducer'
import { RootState } from 'store/types'

import { getAstridScore, getBookExercises } from '../api'
import './BookFactory.scss'

interface IBookFactoryProps {
  book: Book
  country?: string
  bookContext: BookContext
  onCompleteBook: () => void
}

interface IState {
  exerciseIndex: number
  exercises: IExercise[]
}

class BookFactory extends React.Component<IBookFactoryProps, IState> {
  state: IState = {
    exerciseIndex: 0,
    exercises: []
  }

  async componentDidMount() {
    try {
      const { data } = await getBookExercises(this.props.book._id)
      this.setState({ exercises: data })
    } catch (error) {}
  }

  goPrev = () => {
    const { exerciseIndex } = this.state
    const { exercises } = this.props.book
    const newIndex = exerciseIndex - 1

    if (newIndex >= 0 && newIndex < exercises.length - 1) {
      this.setState({ exerciseIndex: newIndex })
    }
  }

  goNext = () => {
    const { exerciseIndex, exercises } = this.state
    const isLastBookExercise = exerciseIndex >= exercises.length - 1

    if (isLastBookExercise) {
      this.props.onCompleteBook()
      return
    }

    this.setState((state) => ({ exerciseIndex: state.exerciseIndex + 1 }))
  }

  onNextExercise = () => {}

  render() {
    const { exerciseIndex, exercises } = this.state
    const progress = (exerciseIndex / exercises.length) * 100
    if (exercises.length > 0) {
      return (
        <div className="book-factory">
          <div className="book-factory__progress">
            <div style={{ width: `${progress}%` }} />
          </div>
          <ExerciseTransition
            exercises={exercises}
            country={this.props.country}
            currentIndex={exerciseIndex}
            getAstridScore={getAstridScore as any}
            onCurrentExerciseCompleted={this.goNext}
            trackExerciseActivity={() => {}}
            bookContext={this.props.bookContext}
            astridApiUrl={appConfig.apiUrl}
          />
          <div className="book-factory__navigation">
            <button onClick={this.goNext} aria-label="Next">
              <ArrowRight />
            </button>
            <button onClick={this.goPrev} aria-label="Previous">
              <ArrowRight className="book-factory__icon--rotated" />
            </button>
          </div>
        </div>
      )
    } else return null
  }
}

const connector = connect((state: RootState) => ({
  country: selectUserCountry(state)
}))

export default connector(BookFactory)
