import React, { useCallback, useContext, useEffect, useState } from 'react'

import { AlertContext, Button, HelperText, TextInput, Typography, ValidationRules } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { Formik, FormikHelpers } from 'formik'
import { printApiMessage } from 'shared/api/apiMessages'
import * as Yup from 'yup'

import AuthApi from 'store/services/Auth/authApi'

import { GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME, appConfig } from '../../appConfig'
import styles from './RecoverPassword.module.scss'

interface FormValues {
  email: string
}

const validationSchema = Yup.object().shape({
  email: ValidationRules.email
})

const recaptchaActivated = !!process.env.REACT_APP_RECAPTCHA_SITE_KEY

const RecoverPassword = () => {
  const { showAlert } = useContext(AlertContext)
  const [success, setSuccess] = useState(false)
  const [showCaptchaError, setShowCaptchaError] = useState(false)

  const handleSubmit = useCallback(
    async ({ email }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      try {
        let captchaToken = ''
        if (recaptchaActivated) {
          captchaToken = window.grecaptcha?.enterprise.getResponse()
          if (!captchaToken) {
            setShowCaptchaError(true)
            return
          }
        }
        await AuthApi.sendRecoverPasswordEmail(email, captchaToken)
        setSuccess(true)
      } catch (error) {
        window.grecaptcha?.enterprise.reset()
        showAlert(printApiMessage(error))
        setSubmitting(false)
      }
    },
    [showAlert]
  )

  // Reset error when captcha is completed
  useEffect(() => {
    window[GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME] = () => setShowCaptchaError(false)
  }, [])

  // Load recaptcha script on mount
  useEffect(() => {
    if (recaptchaActivated) {
      const scriptId = 'LoginRecaptchaScript'
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        document.body.removeChild(existingScript)
      }
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://www.google.com/recaptcha/enterprise.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  return (
    <AuthWrapper>
      <div className={styles.container}>
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <Typography variant="h3">Recover password</Typography>
                {success ? (
                  <Typography variant="exerciseS">Email sent. Please check your inbox.</Typography>
                ) : (
                  <TextInput
                    name="recover_email"
                    label="Account Email"
                    value={values.email}
                    onChange={handleChange('email')}
                    error={touched.email && !!errors.email}
                    helperText={(touched.email && errors.email) || ''}
                    onBlur={() => setFieldTouched('email')}
                  />
                )}

                {recaptchaActivated && !success ? (
                  <div
                    className={`g-recaptcha ${styles.captcha}`}
                    data-sitekey={appConfig.recaptchaSiteKey}
                    data-callback={GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME}
                  />
                ) : null}
                {showCaptchaError ? <HelperText text="Please complete the security check." error /> : null}

                <div className={styles.recoverPasswordButtons}>
                  {!success && (
                    <Button color="black" variant="flat" type="submit" disabled={isSubmitting}>
                      Send recover email
                    </Button>
                  )}
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </AuthWrapper>
  )
}

export default RecoverPassword
