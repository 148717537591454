import * as React from 'react'

import { CartesianGrid, ComposedChart, Line, XAxis, YAxis } from 'recharts'

interface ChartProps {
  id: string
  data: any[]
  lineKey: string
  lineGradient: { start: string; end: string }
  yAxisProps?: object
  chartProps?: object
}

const Chart = ({
  id,
  data,
  chartProps,
  lineKey,
  lineGradient,
  yAxisProps,
  children
}: React.PropsWithChildren<ChartProps>) => {
  const values = data.filter((item) => !!item[lineKey]).map((value) => value[lineKey]) as number[]
  const higherValue = Math.max(...values)
  const lowestValue = Math.min(...values)
  return (
    <ComposedChart
      width={300}
      height={300}
      data={data}
      margin={{
        top: 15,
        left: 0,
        right: 0
      }}
      {...chartProps}>
      <defs>
        <linearGradient id={`${id}LineGradient`} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={lineGradient.start} />
          <stop offset="100%" stopColor={lineGradient.end} />
        </linearGradient>
      </defs>

      <CartesianGrid vertical={false} />

      {children}

      <XAxis tickLine={false} axisLine={false} dataKey="day" stroke={'#c8c8c8'} />
      <YAxis tickLine={false} axisLine={false} width={40} stroke={'#c8c8c8'} {...yAxisProps} />

      <Line
        dataKey={lineKey}
        strokeWidth={3}
        stroke={`url(#${id}LineGradient)`}
        activeDot={{ r: 5 }}
        dot={({ value, dataKey, ...props }) => {
          if (!value) return <></>
          return (
            <circle
              {...props}
              strokeWidth={0}
              r={4}
              fill={(value - lowestValue) / (higherValue - lowestValue) > 0.5 ? lineGradient.start : lineGradient.end}
            />
          )
        }}
      />
    </ComposedChart>
  )
}

export default Chart
