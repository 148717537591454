import { useLocation } from 'react-router-dom'

import { HttpErrors } from '@astrid/shared'

const searchParamKey = 'login_result'

export function parseToEnum<E extends { [k: string]: unknown }>(e: E, v: unknown): E[keyof E] | null {
  const enumV = v as E[keyof E]
  if (Object.values(e).indexOf(enumV) < 0) return null
  return enumV
}

export const useLoginResult = (): { loginResult: HttpErrors | null } => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramValue = searchParams.get(searchParamKey)
  return { loginResult: parseToEnum(HttpErrors, paramValue) }
}
