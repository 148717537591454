import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, EditCircleIcon, Modal, Typography, ValidationRules } from '@astrid/components'
import { Formik } from 'formik'
import * as Yup from 'yup'

import AuthApi from 'store/services/Auth/authApi'
import { updateUser } from 'store/services/Auth/authReducer'
import { TeacherData } from 'store/services/Auth/types'

import styles from './ProfileForm.module.scss'

interface ProfileCustomInputProps {
  name: string
  value: string
  onChange: (e: any) => void
  error?: string
  disabled?: boolean
}

const ProfileCustomInput = ({ name, value, onChange, disabled, error }: ProfileCustomInputProps) =>
  disabled ? (
    <span>{value}</span>
  ) : (
    <label className={styles.customInput} data-invalid={!!error}>
      {!disabled && <EditCircleIcon aria-hidden="true" focusable="false" />}
      <input name={name} type="text" value={value} onChange={onChange} aria-label={name} />
      {error && <span>{error}</span>}
    </label>
  )

const validationSchema = Yup.object().shape({
  firstName: ValidationRules.required,
  lastName: ValidationRules.required,
  email: ValidationRules.email
})

const ProfileForm = ({ user, onSubmit }: { user: TeacherData; onSubmit?: () => void }) => {
  const dispatch = useDispatch()
  const [emailChangeModal, setEmailChangeModal] = useState<string | boolean>(false)
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        firstName: user.profile?.firstName || '',
        lastName: user.profile?.lastName || '',
        email: user.email || '',
        school: user.profile?.school || ''
      }}
      onSubmit={async (values, formikBag) => {
        try {
          await dispatch(
            updateUser({
              firstName: values.firstName,
              lastName: values.lastName,
              school: values.school
            })
          )
          if (user.email !== values.email) {
            await AuthApi.requestEmailChange(values.email)
            setEmailChangeModal(values.email)
            formikBag.resetForm()
          }
        } catch (error) {}
        if (onSubmit) await onSubmit()
      }}>
      {({ values, handleChange, dirty, errors, handleSubmit }) => (
        <>
          <form className={styles.container} onSubmit={handleSubmit}>
            <span className={styles.row}>
              <Typography variant="body">First name</Typography>
              <ProfileCustomInput
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                error={errors.firstName}
              />
            </span>
            <span className={styles.row}>
              <Typography variant="body">Last name</Typography>
              <ProfileCustomInput
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                error={errors.lastName}
              />
            </span>
            <span className={styles.row}>
              <Typography variant="body">Email</Typography>
              <ProfileCustomInput
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                disabled
              />
            </span>
            <span className={styles.row}>
              <Typography variant="body">School</Typography>
              <ProfileCustomInput
                name="school"
                value={values.school}
                onChange={handleChange}
                error={errors.school}
                disabled
              />
            </span>
            <div className={styles.controls}>
              <Button type="submit" disabled={!dirty} size="small" variant="flat">
                Save
              </Button>
            </div>
          </form>
          <Modal open={!!emailChangeModal} closeModal={() => setEmailChangeModal(false)} variant="student">
            <Typography variant="exerciseS">Email change request</Typography>
            <Typography variant="body">
              Confirmation email has been sent to {emailChangeModal}. Please confirm your email in order to change it.
            </Typography>
          </Modal>
        </>
      )}
    </Formik>
  )
}

export default ProfileForm
