import React, { useRef, useState } from 'react'

import { ShareIcon, Typography } from '@astrid/components'
import { Popover } from '@material-ui/core'
import { trackButtonClick } from 'analytics/analytics'
import { ReactComponent as ExcelIcon } from 'shared/assets/excel.svg'
import { ReactComponent as ExportIcon } from 'shared/assets/export.svg'
import { ReactComponent as AddIcon } from 'shared/assets/plus-circle.svg'

import styles from '../StudentsTable.module.scss'

interface StudentTableToolbarProps {
  canEdit: boolean
  onAddStudent: () => void
  exportStudentsUrl: string
  studentsCount: number
  onUploadClassList: () => void
}

const StudentTableToolbar = ({
  canEdit,
  onAddStudent,
  exportStudentsUrl,
  studentsCount,
  onUploadClassList
}: StudentTableToolbarProps) => {
  const popoverAnchorEl = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const handleShareClick = () => {
    trackButtonClick({ componentName: 'Share student logins button', componentValue: '' })
    setPopoverOpen(true)
  }

  const canShare = canEdit && studentsCount > 0

  return (
    <div className={styles.toolbar}>
      <Typography variant="h4">Student list</Typography>
      <div>
        {canEdit ? (
          <>
            <button
              disabled={!canEdit}
              className={styles.toolbar__item}
              onClick={onAddStudent}
              data-testid="student-add-button">
              <AddIcon aria-hidden="true" focusable="false" /> Add student
            </button>

            <button disabled={!canEdit} className={styles.toolbar__item} onClick={onUploadClassList}>
              <ExcelIcon aria-hidden="true" focusable="false" /> Upload class list
            </button>
          </>
        ) : null}

        {canShare ? (
          <>
            <button className={styles.toolbar__item_share} ref={popoverAnchorEl} onClick={handleShareClick}>
              <ShareIcon aria-hidden="true" focusable="false" /> Share student logins
            </button>
            <Popover
              open={popoverOpen}
              anchorEl={popoverAnchorEl.current}
              onClose={() => setPopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <div className={styles.toolbar__popover}>
                <a
                  className={styles.toolbar__popover__item}
                  onClick={() => trackButtonClick({ componentName: 'Download login PDF link', componentValue: '' })}
                  download="students_logins"
                  href={exportStudentsUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  <ExportIcon aria-hidden="true" focusable="false" /> Download login PDF
                </a>
              </div>
            </Popover>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default StudentTableToolbar
