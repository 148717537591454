import { HttpErrors } from '@astrid/shared'

const DefaultMessage = 'Something went wrong, try again later...'

export const messages: Partial<{ [k in HttpErrors]: string }> & { DEFAULT: string } = {
  [HttpErrors.AccountDisabled]: 'Account has been disabled, please contact support.',
  [HttpErrors.MaxNumberOfStudentsExceeded]: "You've exceeded the maximum number of students allowed.",
  [HttpErrors.SuspiciousBehaviour]: 'We detected suspicious behaviour. Please contact us for help.',
  [HttpErrors.UserNotFound]: 'Login failed.',
  DEFAULT: DefaultMessage
}

const contactSupportMessage = ' Or contact us for help.'

export const printApiMessage = (error: unknown, withSupportAddress = false) => {
  let message: string
  if (typeof error === 'string') {
    message = error in messages ? messages[error] : error
  } else if (error instanceof Error) {
    message = messages[error.message] || DefaultMessage
  } else {
    message = DefaultMessage
  }
  message = withSupportAddress ? message + contactSupportMessage : message

  return message
}
