import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  AlertContext,
  Button,
  GoogleButton,
  HelperText,
  TextInput,
  Typography,
  ValidationRules
} from '@astrid/components'
import { HttpErrors } from '@astrid/shared'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { ScreenNames, trackLogIn } from 'analytics/analytics'
import { Formik, FormikHelpers } from 'formik'
import { ROUTES } from 'routes/routes'
import { printApiMessage } from 'shared/api/apiMessages'
import { GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME, appConfig } from 'shared/appConfig'
import * as Yup from 'yup'

import { useLoginResult } from 'hooks/useLoginResult'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { loginUser } from 'store/services/Auth/authReducer'
import { AuthCredentials } from 'store/services/Auth/types'
import { ThunkDispatch } from 'store/types'

import styles from './Login.module.scss'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required
})

const Login = () => {
  useTrackScreenShown(ScreenNames.LogIn)
  const { showAlert } = useContext(AlertContext)
  const [showCaptchaError, setShowCaptchaError] = useState(false)

  const dispatch = useDispatch<ThunkDispatch>()
  const { loginResult } = useLoginResult()

  const recaptchaActivated = !!process.env.REACT_APP_RECAPTCHA_SITE_KEY

  const handleSubmit = useCallback(
    async ({ email, password }: AuthCredentials, { setSubmitting }: FormikHelpers<AuthCredentials>) => {
      try {
        let captchaToken = ''
        if (recaptchaActivated) {
          captchaToken = window.grecaptcha?.enterprise.getResponse()
          if (!captchaToken) {
            setShowCaptchaError(true)
            return
          }
        }
        await dispatch(loginUser({ email, password, captchaToken }))
        trackLogIn()
      } catch (error) {
        window.grecaptcha?.enterprise.reset()
        showAlert(printApiMessage(error))
        setSubmitting(false)
      }
    },
    [dispatch, showAlert, recaptchaActivated]
  )

  useEffect(() => {
    if (loginResult === HttpErrors.UserNotFound) {
      showAlert(printApiMessage("Can't sign up with Google"))
    }
  }, [loginResult, showAlert])

  // Reset error when captcha is completed
  useEffect(() => {
    window[GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME] = () => setShowCaptchaError(false)
  }, [])

  // Load recaptcha script on mount
  useEffect(() => {
    if (recaptchaActivated) {
      const scriptId = 'LoginRecaptchaScript'
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        document.body.removeChild(existingScript)
      }
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://www.google.com/recaptcha/enterprise.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [recaptchaActivated])

  return (
    <AuthWrapper>
      <div className={styles.content}>
        <Typography variant="h1" style={{ margin: 0 }}>
          Log in
        </Typography>
        <Typography variant="body">with your email and password</Typography>

        <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="email"
                  label="Your Email"
                  value={values.email}
                  onChange={handleChange('email')}
                  error={touched.email && !!errors.email}
                  helperText={(touched.email && errors.email) || ''}
                  onBlur={() => setFieldTouched('email')}
                />
                <TextInput
                  name="password"
                  label="Enter Password"
                  type="password"
                  value={values.password}
                  onChange={handleChange('password')}
                  error={touched.password && !!errors.password}
                  helperText={(touched.password && errors.password) || ''}
                  onBlur={() => setFieldTouched('password')}
                />
                {recaptchaActivated ? (
                  <div
                    className={`g-recaptcha ${styles.captcha}`}
                    data-sitekey={appConfig.recaptchaSiteKey}
                    data-callback={GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME}
                  />
                ) : null}
                {showCaptchaError ? <HelperText text="Please complete the security check." error /> : null}
                <Button type="submit" variant="flat" color="black" disabled={isSubmitting}>
                  Log in
                </Button>
              </form>
            )
          }}
        </Formik>

        <Typography variant="body">or</Typography>

        <GoogleButton color="secondary" onClick={() => window.location.replace(`${appConfig.apiUrl}/auth/teacher`)} />

        <Link to={ROUTES.REQUEST_PASSWORD_RESET}>
          <Typography variant="body" color="secondary" className={styles.forgot}>
            Forgot password?
          </Typography>
        </Link>
      </div>

      <div className={styles.footer}>
        <Typography variant="body" color="secondary">
          By signing in to Astrid, you agree to our{' '}
          <a href="https://policies.withastrid.com/" target="_blank" rel="noopener noreferrer">
            Terms
          </a>{' '}
          and{' '}
          <a
            href="https://policies.withastrid.com/privacy-policy/latest.html"
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </Typography>

        <Typography variant="body">
          Contact us at <a href="mailto:support@astrideducation.com">support@astrideducation.com</a>
        </Typography>
      </div>
    </AuthWrapper>
  )
}

export default Login
