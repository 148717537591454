import { AxiosPromise } from 'axios'
import API from 'shared/api/API'

import { Statistic, StudentGoals } from './types'

class StatisticsApi {
  static getStatisticsByClassId = (classId: string, period?: '6months' | number): AxiosPromise<Statistic[]> =>
    API.get(`/students-class/${classId}/statistics`, {
      params: {
        $filter: `period:${period}`
      }
    })
  static updateStudentsGoals = (classId: string, studentsGoals: StudentGoals[]): AxiosPromise<Statistic[]> =>
    API.patch(`/students-class/${classId}/statistics`, studentsGoals)
}

export default StatisticsApi
