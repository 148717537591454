import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link, NavLink, RouteComponentProps } from 'react-router-dom'

import { Typography } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { ScreenNames } from 'analytics/analytics'
import { ROUTES } from 'routes/routes'
import imageSuccess from 'shared/assets/abbe-happy.png'
import imageFail from 'shared/assets/abbe-sad.png'
import PageLoader from 'shared/components/PageLoader/PageLoader'
import { Logger } from 'shared/logger/Logger'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import AuthApi from 'store/services/Auth/authApi'

const VerifyEmail = ({ match, ...props }: RouteComponentProps<{ token: string }>) => {
  useTrackScreenShown(ScreenNames.VerifyEmail)
  const token = match.params.token

  const [activationState, setActivationState] = useState<{
    loading: boolean
    resend: boolean
    success: null | boolean
  }>({
    loading: true,
    resend: false,
    success: null
  })

  const resendEmail = React.useCallback(async () => {
    try {
      await AuthApi.resendVerificationEmail()
      setActivationState((state) => ({ ...state, resend: true }))
    } catch (error) {}
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        await AuthApi.verify(token)
        setActivationState((state) => ({ ...state, success: true }))
      } catch (err) {
        setActivationState((state) => ({ ...state, success: false }))
        Logger.log(err)
      } finally {
        setActivationState((state) => ({ ...state, loading: false }))
      }
    })()
  }, [token])

  if (activationState.loading) {
    return <PageLoader />
  } else {
    if (activationState.success) {
      return (
        <AuthWrapper noBackground>
          <img src={imageSuccess} alt={''} />
          <Typography variant="h1" style={{ margin: 0 }}>
            Hooray!
          </Typography>
          <Typography variant="body">Email address verified.</Typography>
          <Typography variant="body" color="secondary">
            You can now <Link to={ROUTES.ROOT}>log in</Link>.
          </Typography>
        </AuthWrapper>
      )
    } else {
      return (
        <>
          <AuthWrapper noBackground>
            <img src={imageFail} style={{ margin: '40px 0' }} alt={''} />
            <Typography variant="h1" style={{ margin: 0 }}>
              Fail!
            </Typography>
            <Typography variant="body">Activation link is not valid anymore</Typography>
            <Typography variant="body" color="secondary">
              {activationState.resend ? (
                'Activation link has been resent. Check your email or '
              ) : (
                <>
                  <span onClick={resendEmail}>Resend activation link</span> or back to{' '}
                </>
              )}
              <NavLink to={ROUTES.ROOT}>log in</NavLink>
            </Typography>
          </AuthWrapper>
        </>
      )
    }
  }
}

export default VerifyEmail
