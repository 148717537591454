import * as React from 'react'
import { Link } from 'react-router-dom'

import leftGraphic from '@astrid/components/dist/graphics/welcome-left.png'
import rightGraphic from '@astrid/components/dist/graphics/welcome-right.png'
import styles from 'Auth/AuthWrapper/AuthWrapper.module.scss'
import { ROUTES } from 'routes/routes'
import { ReactComponent as AstridLogo } from 'shared/assets/astrid_logo_long.svg'

interface Props {
  noBackground?: boolean
}

const AuthWrapper = ({ children, noBackground = false }: React.PropsWithChildren<Props>) => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to={ROUTES.ROOT}>
          <AstridLogo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.content}>{children}</div>
      {!noBackground && (
        <>
          <img className={styles.leftGraphic} src={leftGraphic} alt="characters-left" />
          <img className={styles.rightGraphic} src={rightGraphic} alt="characters-right" />
        </>
      )}
    </div>
  </div>
)

export default AuthWrapper
