import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GridCellRenderer } from 'react-virtualized'

import { ScreenNames } from 'analytics/analytics'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'

import { selectAvailableContentFrameworks } from '../store/services/Auth/authReducer'
import { ContentFramework, LibraryBookEntry, LibraryResponse, getLibrary } from '../store/services/Library'
import styles from './LibraryView.module.scss'
import { BookEntryCard } from './fragments/BookCard'
import { ContentFrameworkSelector } from './fragments/ContentFrameworkSelector/ContentFrameworkSelector'
import { LibraryBookModal } from './fragments/LibraryBookModal/LibraryBookModal'
import LibraryViewSection from './fragments/LibraryViewSection/LibraryViewSection'

function LibraryView() {
  useTrackScreenShown(ScreenNames.Library)
  const [, setLoading] = useState(false)
  const [libraryResponse, setLibraryResponse] = useState<LibraryResponse | null>(null)
  const [bookForActionModal, setBookForActionModal] = useState<LibraryBookEntry | null>(null)
  const availableContentFrameworks = useSelector(selectAvailableContentFrameworks)
  const [selectedContentFramework, setSelectedContentFramework] = useState<string>(
    availableContentFrameworks[0] ?? ContentFramework.CEFR
  )

  // TODO: prevent excess fetching (store? session storage?)
  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      setLoading(true)
      try {
        const { data } = await getLibrary(selectedContentFramework, controller.signal)
        setLibraryResponse(data)
      } catch (error) {
        // Should be handled by interceptor
      } finally {
        setLoading(false)
      }
    })()
    return () => {
      controller.abort()
    }
  }, [selectedContentFramework])

  const handleBookEntryClick = (bookEntry: LibraryBookEntry) => setBookForActionModal(bookEntry)
  const handleBookModalClose = () => setBookForActionModal(null)

  return (
    <div className={styles.container}>
      {/* TODO: loading indicator */}
      {availableContentFrameworks.length > 1 ? (
        <ContentFrameworkSelector
          selection={selectedContentFramework}
          availableFrameworks={availableContentFrameworks}
          onSelect={setSelectedContentFramework}
        />
      ) : null}
      {libraryResponse?.shelves.map((shelf) => {
        const section = shelf.sections[0]
        if (!section) {
          console.warn('Shelf missing sections', shelf.title)
          return null
        }
        if (!section.entries.length) return null // avoid rendering empty shelves
        const booksToRender = section.entries.filter((e) => e.type === 'book').map((e) => e.data)
        const cellRenderer: GridCellRenderer = ({ columnIndex, key, style }) => {
          const book = booksToRender[columnIndex]
          return (
            <div key={key} style={{ ...style, padding: '0 8px' }}>
              <BookEntryCard book={book} onClick={handleBookEntryClick} />
            </div>
          )
        }

        return (
          <LibraryViewSection
            cellRenderer={cellRenderer}
            label={shelf.title}
            key={shelf.title}
            columnCount={booksToRender.length}
          />
        )
      })}

      <LibraryBookModal bookEntry={bookForActionModal} onClose={handleBookModalClose} />
    </div>
  )
}

export default LibraryView
