import React from 'react'
import ReactDOM from 'react-dom'

import { initHotjar } from '@astrid/components'
import * as Sentry from '@sentry/react'
import App from 'App'
import { appConfig } from 'shared/appConfig'
import { initGoogleTagManager } from 'shared/helpers/GoogleTagManager'

import * as serviceWorker from './serviceWorker'

if (appConfig.environment !== 'development' && appConfig.googleTagManagerId) {
  initGoogleTagManager(appConfig.googleTagManagerId)
}

if (appConfig.environment !== 'development' && appConfig.sentry.dsn) {
  const environment = appConfig.sentry.environment || appConfig.environment
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    environment,
    release: appConfig.sentry.release
  })
}

if (appConfig.environment !== 'development' && appConfig.hotjarId && typeof appConfig.hotjarId === 'string') {
  initHotjar(appConfig.hotjarId)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
