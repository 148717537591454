import { useCallback, useEffect } from 'react'

// Enable key events for elements with button role
export const useHandleKeyEvents = () => {
  const isInteractiveElement = (activeElement: Element | null): activeElement is HTMLElement =>
    activeElement instanceof HTMLElement && ['button', 'tab'].includes(activeElement.getAttribute('role') ?? '')

  const handleKeyDownEvent = useCallback((e: KeyboardEvent) => {
    const { activeElement } = document

    if (isInteractiveElement(activeElement)) {
      // prevent scrolling on spacebar down
      if (e.key === 'Spacebar' || e.key === ' ') {
        e.preventDefault()
      }

      if (e.key === 'Enter') {
        activeElement.click()
      }
    }
  }, [])

  const handleKeyUpEvent = useCallback((e: KeyboardEvent) => {
    const { activeElement } = document

    if (isInteractiveElement(activeElement)) {
      if (e.key === 'Spacebar' || e.key === ' ') {
        activeElement.click()
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownEvent)
    document.addEventListener('keyup', handleKeyUpEvent)

    return () => {
      document.removeEventListener('keydown', handleKeyDownEvent)
      document.removeEventListener('keyup', handleKeyUpEvent)
    }
  }, [handleKeyDownEvent, handleKeyUpEvent])
}
