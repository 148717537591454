import { Dispatch } from 'redux'
import { createSelector } from 'reselect'
import { Logger } from 'shared/logger/Logger'

import { RootState } from 'store/types'

import StudyTimeAPI from './api'
import * as Types from './types'

export const StudyTimeInitialState: Types.StudyTimeState = {
  entities: []
}

export const reducer = (state: Types.StudyTimeState = StudyTimeInitialState, action: Types.StudyTimeAction) => {
  switch (action.type) {
    case Types.STUDY_TIME_ACTIONS.LOAD_STUDY_TIME_BY_CLASS_ID:
      return {
        ...state,
        entities: [...state.entities.filter((entity) => entity.classId !== action.entity.classId), action.entity]
      }
    default:
      return state
  }
}

export default reducer

/** ACTION CREATORS */

export const loadStudyTimeByClassId =
  (classId: string, $limit: number, $skip: number) =>
  async (dispatch: Dispatch<Types.LoadStudyTimeByClassIdAction>) => {
    try {
      const { data } = await StudyTimeAPI.getStudyTimeByClassId(classId, $limit, $skip)
      dispatch({
        type: Types.STUDY_TIME_ACTIONS.LOAD_STUDY_TIME_BY_CLASS_ID,
        entity: data
      })
    } catch (error) {
      Logger.log(error)
    }
  }

/** SELECTORS */

export const selectStudyTime = (state: RootState) => state.studyTime.entities

export const selectStudyTimeByClassId = createSelector(
  selectStudyTime,
  (_: any, classId: string) => classId,
  (entities, classId) => entities.find((entity) => entity.classId === classId)
)
