import React from 'react'

import { Book, StarIcon } from '@astrid/components'

import { LibraryBookEntry } from '../../../store/services/Library'
import styles from './BookCard.module.scss'

type BookCardProps<TBook = Book | LibraryBookEntry> = { book: TBook; onClick?: (book: TBook) => void }

/**
 * Legacy book card used for displaying `BookApiDoc` instances.
 */
export function BookCard({ book, onClick }: BookCardProps<Book>) {
  const onClick_ = () => onClick?.(book)
  return (
    <div
      role="button"
      tabIndex={0}
      className={`${styles.container} ${onClick ? styles.clickable : undefined}`}
      onClick={onClick_}>
      <img className={styles.cover} src={book.imageUrl} alt="Book cover" />
      <div className={styles.info}>
        {book.title}
        <div className={styles.points} aria-label="Star points">
          <StarIcon role="img" aria-label="Star Icon" focusable="false" />
          {book.totalPoints}
        </div>
      </div>
    </div>
  )
}

/**
 * Used for library entries of type `book`
 */
export function BookEntryCard({ book, onClick }: BookCardProps<LibraryBookEntry>) {
  const onClick_ = () => onClick?.(book)
  return (
    <div
      role="button"
      tabIndex={0}
      className={`${styles.container} ${onClick ? styles.clickable : undefined}`}
      onClick={onClick_}>
      <img className={styles.cover} src={book.imageUrl} alt="Book cover" />
      <div className={styles.info}>
        {book.title}
        <div className={styles.points} aria-label="Star points">
          <StarIcon role="img" aria-label="Star Icon" focusable="false" />
          {book.points}
        </div>
      </div>
    </div>
  )
}
