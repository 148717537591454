import React, { PropsWithChildren, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ROUTES } from 'routes/routes'

import { selectUser } from 'store/services/Auth/authReducer'
import { VerificationStatus } from 'store/services/Auth/types'

const EmailWall = ({ children }: PropsWithChildren<{}>) => {
  const user = useSelector(selectUser)
  const history = useHistory()

  useEffect(() => {
    if (user && user.verificationStatus !== VerificationStatus.VERIFIED) {
      history.push(`${ROUTES.ACTIVATE_EMAIL}/${user.email}`)
    }
    // eslint-disable-next-line
  }, [])

  return <>{children}</>
}

export default EmailWall
