export enum ApiErrorCodes {
  FileSizeExceeded = 'MAX_FILE_SIZE_EXCEEDED',
  ExerciseNotFound = 'EXERCISE_NOT_FOUND',
  BookNotFound = 'BOOK_NOT_FOUND',
  BookProgressNotFound = 'BOOK_PROGRESS_NOT_FOUND',
  AdminWrongCredentials = 'ADMIN_WRONG_CREDENTIALS',
  TeacherWrongCredentials = 'TEACHER_WRONG_CREDENTIALS',
  ImageNotUploaded = 'IMAGE_NOT_UPLOADED',
  BookProgressAlreadyExists = 'BOOK_PROGRESS_ALREADY_EXISTS',
  ProfileAlreadyExists = 'PROFILE_ALREADY_EXISTS',
  ProfileNotFound = 'PROFILE_NOT_FOUND',
  BookPublished = 'BOOK_PUBLISHED',
  TeacherExists = 'TEACHER_EXISTS',
  UserNotFound = 'USER_NOT_FOUND',
  TeacherNoPassword = 'TEACHER_DID_NOT_SET_A_PASSWORD',
  UserNoPassword = 'USER_DID_NOT_SET_A_PASSWORD',
  UserExists = 'USER_EXISTS',
  UserWrongCredentials = 'USER_WRONG_CREDENTIALS',
  MaxNumberOfStudentsExceeded = 'MAXIMUM_NUMBER_OF_STUDENTS_IN_CLASS_EXCEEDED',
  GeneralAuthError = 'GENERAL_AUTH_ERROR'
}
