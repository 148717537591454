import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@astrid/components'
import { ROUTES } from 'routes/routes'
import StudyTimeCell from 'shared/components/Overview/StudyTimeCell'

import { StudyTimeEntity } from 'store/services/StudyTime/types'

import styles from './StudyTimeTable.module.scss'

const rowForUser = (timeSpentGoal: number, userStudyTime) => {
  return userStudyTime.map((userWeek) => {
    const beforeCreationTime = userWeek.beforeCreationTime ?? false
    const timeSpent = userWeek.timeSpent !== undefined ? `${userWeek.timeSpent} min` : beforeCreationTime ? '' : '-'
    const { monday, sunday } = userWeek.week
    const key = `${userStudyTime.userId}-${monday}-${sunday}`
    if (beforeCreationTime)
      return (
        <td key={key} className={styles.beforeCreationTime}>
          {timeSpent}
        </td>
      )
    else
      return (
        <StudyTimeCell key={key} goal={timeSpentGoal} score={userWeek.timeSpent}>
          {timeSpent}
        </StudyTimeCell>
      )
  })
}

interface Props {
  classId: string
  studyTimeData: StudyTimeEntity | undefined
  onPrev: () => void
  onNext: () => void
  enableNext: boolean
}

const StudyTimeTable = (props: Props) => {
  const studyTimeData = props.studyTimeData
  if (studyTimeData === undefined || studyTimeData.weeks.length < 1) return null

  const columnHeadFmt = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' })
  const weekHeaders = studyTimeData.weeks.map(({ monday, sunday }) => (
    <th key={`${monday}-${sunday}`}>
      {columnHeadFmt.format(new Date(monday))} - {columnHeadFmt.format(new Date(sunday))}
    </th>
  ))

  const studentRows = studyTimeData.studyTimes.map((studyTime) => (
    <tr key={studyTime.userId}>
      <td>
        <Link to={`${ROUTES.STUDENT}/${props.classId}/${studyTime.userId}`}>
          {studyTime.firstName} {studyTime.lastName}
        </Link>
      </td>
      {rowForUser(studyTime.timeSpentGoal, studyTime.weeks)}
    </tr>
  ))

  return (
    <>
      <div className={styles.lastUpdated}>Updated daily</div>

      <section className={styles.container}>
        <table>
          <thead>
            <tr>
              <th colSpan={studyTimeData.weeks.length + 1}>
                <Button
                  label={'Previous Weeks'}
                  onClick={props.onPrev}
                  className={styles.leftButton}
                  size={'small'}
                  variant={'flat'}
                />
                {props.enableNext && (
                  <Button
                    label={'Next Weeks'}
                    className={styles.rightButton}
                    onClick={props.onNext}
                    size={'small'}
                    variant={'flat'}
                  />
                )}
              </th>
            </tr>
            <tr>
              <th>Name</th>
              {weekHeaders}
            </tr>
          </thead>
          <tbody>{studentRows}</tbody>
        </table>
      </section>
    </>
  )
}

export default StudyTimeTable
