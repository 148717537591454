import { useEffect } from 'react'

import { ScreenNames, trackScreenShown } from 'analytics/analytics'

export const useTrackScreenShown = (screenName?: ScreenNames) => {
  useEffect(() => {
    if (screenName) {
      trackScreenShown(screenName)
    }
  }, [screenName])
}
