import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AvatarUpload, Button, EditCircleIcon, Modal, PasswordChangeForm, Typography } from '@astrid/components'
import { ScreenNames } from 'analytics/analytics'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import AuthApi from 'store/services/Auth/authApi'
import { selectUser, updateUser, uploadAvatar } from 'store/services/Auth/authReducer'

import styles from './Profile.module.scss'
import ProfileForm from './ProfileForm/ProfileForm'

const Profile = () => {
  useTrackScreenShown(ScreenNames.Profile)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const uploadUserAvatar = useCallback(
    async (file: File) => {
      const formData = new FormData()
      formData.append('avatar', file)
      try {
        await dispatch(uploadAvatar(formData))
      } catch (error) {}
    },
    [dispatch]
  )

  const removeAvatar = useCallback(async () => {
    try {
      await dispatch(updateUser({ avatar: null }))
    } catch (error) {}
  }, [dispatch])

  const changePassword = useCallback(async (newPass: string) => {
    try {
      await AuthApi.changePassword(newPass)
      setModalOpen(false)
    } catch (error) {
      console.warn('Password change error')
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <AvatarUpload
        className={styles.upload}
        file={user?.profile?.avatar}
        uploadImage={uploadUserAvatar}
        removeImage={removeAvatar}
      />
      <div className={styles.content}>
        <Typography variant="h3">Account settings</Typography>
        <ProfileForm user={user!} />
        <Typography variant="h4">Security</Typography>
        <div className={styles.security}>
          {!user?.googleId && (
            <span role="button" tabIndex={0} onClick={() => setModalOpen(true)}>
              <Typography variant="body">Change password</Typography>
              <EditCircleIcon aria-hidden="true" focusable="false" />
            </span>
          )}
          <Typography variant="body" color="secondary">
            If you want to delete your account, please contact support
          </Typography>
        </div>
        <Button color="secondary" variant="flat" size="small" className={styles.logout} onClick={AuthApi.logout}>
          Logout
        </Button>
        <Modal open={modalOpen} hideCloseButton closeModal={() => setModalOpen(false)} variant="student">
          <PasswordChangeForm
            className={styles.changePasswordForm}
            onSubmit={changePassword}
            onCancel={() => setModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  )
}

export default Profile
