import { appConfig } from 'shared/appConfig'

const domain = appConfig.domain ? ` Domain=${appConfig.domain}` : window.location.hostname

class Cookies {
  static setItem = (name: string, value: string, days: number) => {
    let expires = ''
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + `; path=/;${domain}`
  }

  static getItem = (name: string) => {
    let nameEQ = name + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  static removeItem = (name: string) => {
    document.cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=/;${domain}`
  }
}

export default Cookies
