import * as React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { AlertProvider, SoundEffectsProvider } from '@astrid/components'
import RouteStack from 'routes/RouteStack'

import { useHandleKeyEvents } from 'hooks/useHandleKeyEvents'
import initStore from 'store/store'

import './index.scss'

function App() {
  const store = initStore()

  useHandleKeyEvents()

  return (
    <ReduxProvider store={store}>
      <SoundEffectsProvider>
        <AlertProvider>
          <RouteStack />
        </AlertProvider>
        <div id="draggable" />
      </SoundEffectsProvider>
    </ReduxProvider>
  )
}

export default App
