import React, { ReactNode } from 'react'

import styles from './Tabs.module.scss'

interface Tab<T> {
  key: T
  label: string
  children: ReactNode
}

interface TabsProps<T> {
  items: Tab<T>[]
  activeTabKey: T
  onChange?: (tabKey: T) => void
  NavigationRightElement?: ReactNode
}

function Tabs<T extends string = string>({ items, activeTabKey, onChange, NavigationRightElement }: TabsProps<T>) {
  const { key: activeKey, children } = items.find((item) => item.key === activeTabKey) || items[0]
  return (
    <>
      <div className={styles.navigationContainer}>
        <ul className={styles.navigation}>
          {items.map((item) => (
            <li
              className={`${styles.tab} ${activeKey === item.key ? styles.activeTab : ''}`}
              key={`${item.key}`}
              onClick={() => onChange?.(item.key)}
              tabIndex={0}
              role="tab">
              {item.label}
            </li>
          ))}
        </ul>
        {NavigationRightElement}
      </div>
      <div>{children}</div>
    </>
  )
}

export default Tabs
