import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertContext, CancelConfirmButtons, Typography, ValidationRules } from '@astrid/components'
import { Radio } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { ROUTES } from 'routes/routes'
import { printApiMessage } from 'shared/api/apiMessages'
import PageLoader from 'shared/components/PageLoader/PageLoader'
import useSWR from 'swr'
import * as Yup from 'yup'

import ClassesApi from 'store/services/Classes/api'

import styles from './GoogleClassroom.module.scss'

interface FormFields {
  cefrLevel: string
  googleClassId: string
}

const validationSchema = Yup.object().shape({
  cefrLevel: ValidationRules.required,
  googleClassId: ValidationRules.required
})

const GoogleClassroom = ({ onCancel }: { onCancel: () => void }) => {
  const { data: googleCourses } = useSWR('google-courses', () =>
    ClassesApi.getGoogleClassroomCourses().then((res) => res.data)
  )
  const history = useHistory()
  const { showAlert } = useContext(AlertContext)

  const handleSubmit = useCallback(
    async (formValues: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      const { cefrLevel, googleClassId } = formValues
      try {
        const response = await ClassesApi.importGoogleClassroomCourse({ googleClassId, cefrLevel })
        history.push(`${ROUTES.CLASSES}/${response.data._id}`)
      } catch {
        showAlert(printApiMessage('Some values might be incorrect or connection problem occured.'))
        setSubmitting(false)
      }
    },
    [showAlert, history]
  )

  const radioAndLabel = (level, text, currentLevel, setFieldValue) => (
    <label>
      <Radio
        checked={currentLevel === level}
        onChange={() => setFieldValue('cefrLevel', level)}
        value={level}
        name="cefrLevel"
        color="primary"
      />
      {text}
    </label>
  )

  return (
    <div className={styles.container}>
      <Typography variant="h2" component="h1">
        Import from Google Classroom
      </Typography>

      {googleCourses ? (
        <div>
          <Formik
            initialValues={{
              cefrLevel: 'A1.1',
              googleClassId: ''
            }}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            onSubmit={handleSubmit}>
            {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
              const cefrLevel = values.cefrLevel
              return (
                <Form>
                  <div className={styles.wrapper}>
                    <Typography className={styles.questionText} variant="exerciseS" component="div">
                      Choose class to import
                    </Typography>

                    <ol className={styles.list}>
                      {googleCourses.courses.map((course) => (
                        <li key={course.externalId}>
                          <label>
                            <Radio
                              checked={values.googleClassId === course.externalId}
                              onChange={() => setFieldValue('googleClassId', course.externalId)}
                              value={course.externalId}
                              name="googleClassId"
                              color="primary"
                            />
                            <div className={styles.labelContent}>
                              {course.name}
                              <Typography variant="label" component="span">
                                {course.description}
                              </Typography>
                              {!!course.studentsClassId ? (
                                <Typography variant="label" component="span">
                                  {` already imported`}
                                </Typography>
                              ) : null}
                            </div>
                          </label>
                        </li>
                      ))}
                    </ol>

                    <Typography className={styles.questionText} variant="exerciseS" component="div">
                      What do you expect these students to be able to do in English?
                    </Typography>

                    <ol className={styles.list}>
                      {radioAndLabel(
                        'A1.1',
                        'A1: Use common expressions and very basic phrases.',
                        cefrLevel,
                        setFieldValue
                      )}
                      {radioAndLabel('A2.1', 'A2: Talk about things they see and want.', cefrLevel, setFieldValue)}
                      {radioAndLabel('B1.1', 'B1: Describe their opinions and plans.', cefrLevel, setFieldValue)}
                    </ol>
                  </div>

                  <CancelConfirmButtons
                    disableConfirm={isSubmitting || !values.googleClassId}
                    onCancel={onCancel}
                    onConfirm={handleSubmit}
                    confirmText="Import"
                    className={styles.confirm}
                  />
                </Form>
              )
            }}
          </Formik>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  )
}

export default GoogleClassroom
