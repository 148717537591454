import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { Headline, Typography } from '@astrid/components'
import { CircularProgress } from '@material-ui/core'
import { ScreenNames } from 'analytics/analytics'
import { ROUTES } from 'routes/routes'
import { ApiReqState } from 'shared/api/types'
import Overview from 'shared/components/Overview/Overview'
import TimePeriodToggle from 'shared/components/TimePeriodToggle/TimePeriodToggle'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { selectUser } from 'store/services/Auth/authReducer'
import { getCurrentClass, selectCurrentClass } from 'store/services/Classes/reducer'
import {
  getClassOverview,
  selectClassOverview,
  selectClassOverviewReqState,
  selectTimePeriod
} from 'store/services/Overview/reducer'

import styles from './ClassOverview.module.scss'

const ClassOverview = ({ match }: RouteComponentProps<{ classId: string; id: string }>) => {
  useTrackScreenShown(ScreenNames.InsightsClass)
  // For now this overview is only for current week
  const displayMonth = false

  const classId = match.params.classId
  const currentClass = useSelector(selectCurrentClass)
  const classOverview = useSelector(selectClassOverview)
  const classOverviewReqState = useSelector(selectClassOverviewReqState)
  const teacher = useSelector(selectUser)
  const period = useSelector(selectTimePeriod)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getClassOverview(classId, period))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [history, classId, period, dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCurrentClass(classId))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [classId, history, dispatch])

  if (!currentClass || !teacher?.profile) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Headline title={currentClass.name} subtitle={teacher.profile.school} imgSrc={teacher.profile.avatar} />
      </div>
      <TimePeriodToggle className={styles.toggle} />
      {classOverviewReqState === ApiReqState.PENDING && (
        <div className={styles.message}>
          <CircularProgress className={styles.spinner} size={18} thickness={4} />
          <Typography variant="body" className={styles.message}>
            Loading...
          </Typography>
        </div>
      )}
      {classOverviewReqState === ApiReqState.REJECTED && (
        <Typography variant="body" className={styles.message}>
          Failed to load data
        </Typography>
      )}
      {classOverviewReqState === ApiReqState.RESOLVED && classOverview && (
        <>
          <span className={styles.lastUpdated}>Updated daily</span>
          <Overview
            overview={classOverview}
            displayMonth={displayMonth}
            showChallengingWordsToPronounce={true}
            showChallengingWordsToSpell={true}
            showChallengingSounds={true}
            showTrending={true}
            showOnlyTimeEffort={false}
            progressSubtitle="Total average"
            effortSubtitle="Total average"
          />
        </>
      )}
    </div>
  )
}

export default ClassOverview
