import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { AlertContext, CancelConfirmButtons, Modal, Typography } from '@astrid/components'
import { CircularProgress, Dialog, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import SheetPreview from 'Classes/SheetPreview/SheetPreview'
import StudentsTable from 'Classes/StudentsTable/StudentsTable'
import { SingleStudentFormValues } from 'Classes/StudentsTable/components/SingleStudentForm'
import UploadClassModal from 'Classes/UploadClassModal/UploadClassModal'
import { ScreenNames } from 'analytics/analytics'
import { printApiMessage } from 'shared/api/apiMessages'
import { ApiReqState } from 'shared/api/types'
import { appConfig } from 'shared/appConfig'
import { Logger } from 'shared/logger/Logger'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import AuthApi from 'store/services/Auth/authApi'
import ClassesApi from 'store/services/Classes/api'
import {
  addMultipleStudents,
  addStudentToClass,
  clearCurrentClass,
  editStudent,
  getCurrentClass,
  removeClass,
  removeStudentFromClass,
  selectCurrentClass,
  selectCurrentClassReqState
} from 'store/services/Classes/reducer'
import { CreateStudentData, StudentsTableRow } from 'store/services/Classes/types'
import { formatStudentDataForApi, transformStudentsFromApiToTable } from 'store/services/Classes/utils'
import { ThunkDispatch } from 'store/types'

import { ROUTES } from '../../routes/routes'
import { selectUserSettings } from '../../store/services/Auth/authReducer'
import styles from './ClassView.module.scss'
import ClassViewHeader from './components/ClassViewHeader'
import { ConfirmDeleteClassDialog } from './components/ConfirmDeleteClassDialog'

const PreviewDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClassView = ({ match }: RouteComponentProps<{ id: string }>) => {
  useTrackScreenShown(ScreenNames.Class)
  const history = useHistory()

  const { id } = match.params
  const dispatch = useDispatch<ThunkDispatch>()
  const currentClassReqState = useSelector(selectCurrentClassReqState)
  const currentClass = useSelector(selectCurrentClass)
  const settings = useSelector(selectUserSettings)
  const { showAlert } = useContext(AlertContext)
  const [studentToDelete, setStudentToDelete] = useState<StudentsTableRow | null>(null)
  const [showUploadStudentsModal, setShowUploadStudentsModal] = useState(false)
  const [parsedStudents, setParsedStudents] = useState<CreateStudentData[]>([])
  const [showSheetPreviewDialog, setShowSheetPreviewDialog] = useState(false)
  const [showConfirmDeleteClassDialog, setShowConfirmDeleteClassDialog] = useState(false)

  const canEdit = settings.allowClassManagement && !currentClass?.externalRef

  const students: StudentsTableRow[] = useMemo(() => {
    if (currentClass) {
      return transformStudentsFromApiToTable(currentClass.users)
    } else {
      return []
    }
  }, [currentClass])

  const onAddStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      try {
        await dispatch(addStudentToClass(id, formatStudentDataForApi(values)))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert]
  )

  const closeSheetDialog = useCallback(() => {
    setParsedStudents([])
    setShowSheetPreviewDialog(false)
  }, [])

  const onAddMultipleStudents = useCallback(
    async (values: SingleStudentFormValues[]) => {
      const students = values.map(formatStudentDataForApi)
      try {
        await dispatch(addMultipleStudents(id, students))
        closeSheetDialog()
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert, closeSheetDialog]
  )

  const onEditStudent = useCallback(
    async (studentId: string, values: SingleStudentFormValues) => {
      try {
        await dispatch(editStudent(id, studentId, formatStudentDataForApi(values)))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert]
  )

  const handleGenerateNewPassword = useCallback(
    async (studentId: string) => {
      try {
        const { newPassword } = await ClassesApi.generateNewPassword(id, studentId)
        return newPassword
      } catch (error) {
        showAlert(printApiMessage(error))
      }
      return null
    },
    [id, showAlert]
  )

  const handleConfirmDeleteClass = useCallback(async () => {
    try {
      await dispatch(removeClass(id))
      history.push(ROUTES.CLASSES)
    } catch (error) {
      showAlert('Something went wrong, please try again.')
    }
  }, [dispatch, history, id, showAlert])

  const handleDeleteClassAction = useCallback(() => {
    if (currentClass?.users.length) {
      setShowConfirmDeleteClassDialog(true)
    } else {
      handleConfirmDeleteClass()
    }
  }, [currentClass?.users.length, handleConfirmDeleteClass])

  const onDeleteStudent = useCallback(async () => {
    if (studentToDelete) {
      try {
        await dispatch(removeStudentFromClass(id, studentToDelete._id))
        setStudentToDelete(null)
      } catch (error) {}
    }
  }, [studentToDelete, id, dispatch])

  const exportStudentsUrl = useMemo(() => {
    return `${appConfig.apiUrl}/export/students-class/${id}/?token=${AuthApi.getUserToken()}`
  }, [id])

  const closeStudentsAddModal = useCallback(() => {
    setShowUploadStudentsModal(false)
  }, [])

  const onFileUpload = useCallback(
    async (file: File) => {
      try {
        const { data } = await ClassesApi.parseSheet(id, file)
        closeStudentsAddModal()
        setParsedStudents(data.parsedStudents)
        setShowSheetPreviewDialog(true)
      } catch (error) {
        showAlert('Document parsing failed. Please check your sheet or try another one.')
      }
    },
    [closeStudentsAddModal, id, showAlert]
  )

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCurrentClass(id))
      } catch (error) {
        Logger.log(error)
      }
    })()

    return () => {
      dispatch(clearCurrentClass)
    }
  }, [id, dispatch])

  if (!currentClass || currentClassReqState === ApiReqState.PENDING) {
    return (
      <>
        {currentClassReqState === ApiReqState.PENDING && (
          <div className={styles.message}>
            <CircularProgress className={styles.spinner} size={18} thickness={4} />
            <Typography variant="body" className={styles.message}>
              Loading class...
            </Typography>
          </div>
        )}
        {currentClassReqState === ApiReqState.REJECTED && (
          <Typography variant="body" className={styles.message}>
            Failed to load class.
          </Typography>
        )}
      </>
    )
  }

  return (
    <section className={styles.container}>
      <ClassViewHeader
        classId={id}
        name={currentClass.name}
        canEdit={canEdit}
        onDeleteClass={handleDeleteClassAction}
      />
      <StudentsTable
        students={students}
        canEdit={canEdit}
        onAddStudent={onAddStudent}
        onEditStudent={onEditStudent}
        exportStudentsUrl={exportStudentsUrl}
        onDeleteStudent={setStudentToDelete}
        onUploadClassList={() => setShowUploadStudentsModal(true)}
        onRegeneratePassword={handleGenerateNewPassword}
      />
      <Modal variant="teacher" open={!!studentToDelete} hideCloseButton closeModal={() => setStudentToDelete(null)}>
        <Typography variant="exerciseS">
          Are you sure you want to delete {`${studentToDelete?.firstName} ${studentToDelete?.lastName} account?`}
        </Typography>
        <CancelConfirmButtons
          onConfirm={onDeleteStudent}
          onCancel={() => setStudentToDelete(null)}
          confirmText="DELETE"
        />
      </Modal>
      <Modal open={showUploadStudentsModal} hideCloseButton variant="teacher" closeModal={closeStudentsAddModal}>
        <UploadClassModal closeModal={closeStudentsAddModal} onFileUpload={onFileUpload} />
      </Modal>
      <Dialog
        fullScreen
        open={showSheetPreviewDialog}
        onClose={closeSheetDialog}
        TransitionComponent={PreviewDialogTransition}>
        <SheetPreview
          onAddMultipleStudents={onAddMultipleStudents}
          parsedStudents={parsedStudents}
          onClosePreview={closeSheetDialog}
        />
      </Dialog>
      <ConfirmDeleteClassDialog
        open={showConfirmDeleteClassDialog}
        className={currentClass.name}
        onConfirm={handleConfirmDeleteClass}
        onCancel={() => setShowConfirmDeleteClassDialog(false)}
      />
    </section>
  )
}

export default ClassView
