import * as React from 'react'

import styles from './Dashboard.module.scss'

const Dashboard = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <main className={styles.container}>
        <div className={styles.inner}>{children}</div>
      </main>
    </>
  )
}

export default Dashboard
