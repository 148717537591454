import { AxiosPromise } from 'axios'
import API from 'shared/api/API'
import { Paginated } from 'shared/api/types'

import {
  CreateStudentData,
  ListGoogleClassroomCoursesResponse,
  SheetParsingResponse,
  StudentClass,
  StudentClassData,
  StudentClassFilled
} from './types'

class ClassesApi {
  static create = (classData: StudentClassData): AxiosPromise<StudentClass> => API.post('/students-class', classData)
  static getClasses = (): AxiosPromise<Paginated<StudentClass>> => API.get('/students-class')
  static getGoogleClassroomCourses = (): AxiosPromise<ListGoogleClassroomCoursesResponse> =>
    API.get('/students-class/external/google-classroom')
  static importGoogleClassroomCourse = ({
    googleClassId,
    cefrLevel
  }: {
    googleClassId: string
    cefrLevel: string
  }): AxiosPromise<StudentClassFilled> =>
    API.post('/students-class/external/google-classroom', { courseId: googleClassId, cefrLevel })
  static getClassById = (classId: string): AxiosPromise<StudentClassFilled> => API.get(`/students-class/${classId}`)
  static updateClass = (classId: string, classData: Partial<StudentClassData>): AxiosPromise<StudentClassFilled> =>
    API.put(`/students-class/${classId}`, classData)
  static deleteStudentFromClass = (classId: string, studentId: string): AxiosPromise<StudentClassFilled> =>
    API.delete(`/students-class/${classId}/user/${studentId}`)
  static addStudentToClass = (classId: string, studentData: CreateStudentData): AxiosPromise<StudentClassFilled> =>
    API.post(`/students-class/${classId}/user`, studentData)
  static deleteClass = (classId: string): AxiosPromise => API.delete(`/students-class/${classId}`)
  static editStudent = (
    classId: string,
    studentId: string,
    studentData: CreateStudentData
  ): AxiosPromise<StudentClassFilled> => API.patch(`/students-class/${classId}/user/${studentId}`, studentData)
  static addMultipleStudents = (classId: string, students: CreateStudentData[]): AxiosPromise<StudentClassFilled> =>
    API.put(`/students-class/${classId}/import`, { parsedStudents: students })

  static parseSheet = (classId: string, file: File): AxiosPromise<SheetParsingResponse> => {
    const data = new FormData()
    data.append('file', file)
    return API.put(`/students-class/${classId}/preview`, data)
  }
  static checkUsernameAvailability = (username: string): AxiosPromise<{ available: boolean }> =>
    API.post('/user/available', { username })
  static generateNewPassword = async (
    classId: string,
    userId: string
  ): Promise<{
    newPassword: string
  }> => {
    const { data } = await API.get<{ newPassword: string }>(`/students-class/${classId}/user/${userId}/new-pass`)
    return data
  }
}

export default ClassesApi
