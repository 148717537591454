import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { CloseIcon, ProfileIcon } from '@astrid/components'
import { ROUTES } from 'routes/routes'
import { ReactComponent as AstridLogo } from 'shared/assets/astrid_logo_long.svg'
import { ReactComponent as BookIcon } from 'shared/assets/book.svg'
import { ReactComponent as ExternalLinkIcon } from 'shared/assets/external-link.svg'
import { ReactComponent as InsightsIcon } from 'shared/assets/insights.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/settings.svg'
import { ReactComponent as TrinityLogo } from 'shared/assets/trinity_logo.svg'

import { selectBranding } from 'store/services/Auth/authReducer'
import { Branding } from 'store/services/Auth/types'

import styles from './Header.module.scss'

const Header = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const branding = useSelector(selectBranding)

  const isBookRoute = pathname.includes(ROUTES.BOOK)
  const isStudentRoute = pathname.includes(ROUTES.STUDENT)
  const isClassRoute = pathname.includes(`${ROUTES.CLASS}/`)
  const showClose = isBookRoute || isStudentRoute || isClassRoute

  const goBack = useCallback(() => {
    if (isBookRoute) {
      history.push(ROUTES.LIBRARY)
      return
    }

    if (isStudentRoute) {
      history.push(ROUTES.INSIGHTS)
      return
    }

    history.goBack()
    //eslint-disable-next-line
  }, [pathname, history])

  const renderLogos = (brand: Branding) => {
    switch (brand) {
      case Branding.TRINITY:
        return <TrinityLogo height={44} role="img" aria-label="Trinity logo" focusable="false" />
      case Branding.ASTRID:
      default:
        return <AstridLogo className={styles.astridLogo} role="img" aria-label="Astrid logo" focusable="false" />
    }
  }

  const headerBranded = branding !== Branding.ASTRID

  return (
    <>
      <header className={styles.root}>
        <div className={styles.container}>
          <Link to={ROUTES.INSIGHTS} aria-label="Home">
            <span className={styles.logos}>{renderLogos(branding)}</span>
          </Link>

          <nav className={styles.controls} role="navigation" aria-label="Main">
            <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.INSIGHTS}>
              {headerBranded ? null : <InsightsIcon aria-hidden="true" focusable="false" />}
              <span>Insights</span>
            </NavLink>
            <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.LIBRARY}>
              {headerBranded ? null : <BookIcon aria-hidden="true" focusable="false" />}
              <span>Library</span>
            </NavLink>
            <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.CLASSES}>
              {headerBranded ? null : <SettingsIcon aria-hidden="true" focusable="false" />}
              <span>My Classes</span>
            </NavLink>
            <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.PROFILE}>
              {headerBranded ? null : <ProfileIcon aria-hidden="true" focusable="false" />}
              <span> Profile</span>
            </NavLink>
            <a
              className={`${styles.link} ${styles.noFill}`}
              href="https://withastrid.notion.site/Teacher-FAQ-7e45b18911bf40928e5ec18b170ba73c"
              target="_blank"
              rel="noopener noreferrer">
              {headerBranded ? null : <ExternalLinkIcon aria-hidden="true" focusable="false" />}
              <span>Help</span>
            </a>
          </nav>
        </div>
      </header>
      {showClose ? (
        <div className={styles.actionBar}>
          <button onClick={goBack} className={styles.close} aria-label="Close">
            <CloseIcon role="img" aria-label="Close Icon" focusable="false" />
          </button>
        </div>
      ) : null}
    </>
  )
}

export default Header
