import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToggleSwitch } from '@astrid/components'
import { trackButtonClick } from 'analytics/analytics'

import { selectTimePeriod, setTimePeriod } from 'store/services/Overview/reducer'

import styles from './TimePeriodToggle.module.scss'

function TimePeriodToggle({ className }: { className?: string }) {
  const dispatch = useDispatch()
  const timePeriod = useSelector(selectTimePeriod)

  const value = timePeriod === '6months'

  return (
    <ToggleSwitch
      className={`${styles.toggle} ${className ?? ''}`}
      name="mode"
      falseLabel="This Week"
      trueLabel="Last 6 months"
      value={value}
      onChange={() => {
        const nextValue = value ? 'week' : '6months'
        trackButtonClick({ componentName: 'Time period toggle', componentValue: nextValue })
        dispatch(setTimePeriod(value ? 7 : '6months'))
      }}
    />
  )
}

export default TimePeriodToggle
