import { combineReducers } from 'redux'

import authReducer, { AuthInitialState } from 'store/services/Auth/authReducer'
import { AuthActions } from 'store/services/Auth/types'
import classesReducer, { ClassesInitialState } from 'store/services/Classes/reducer'
import { ClassesActions } from 'store/services/Classes/types'
import overviewReducer, { OverviewInitialState } from 'store/services/Overview/reducer'
import { OverviewActions } from 'store/services/Overview/types'
import statisticsReducer, { StatisticsInitialState } from 'store/services/Statistics/reducer'
import { StatisticAction } from 'store/services/Statistics/types'
import studyTimeReducer, { StudyTimeInitialState } from 'store/services/StudyTime/reducer'
import { StudyTimeAction } from 'store/services/StudyTime/types'

import { RootState } from './types'

export const combinedReducers = combineReducers({
  auth: authReducer,
  classes: classesReducer,
  overview: overviewReducer,
  statistics: statisticsReducer,
  studyTime: studyTimeReducer
})

export const initialRootState: RootState = {
  auth: AuthInitialState,
  classes: ClassesInitialState,
  overview: OverviewInitialState,
  statistics: StatisticsInitialState,
  studyTime: StudyTimeInitialState
}

export const rootReducer = (
  state = initialRootState,
  action: AuthActions | ClassesActions | StatisticAction | StudyTimeAction | OverviewActions
) => combinedReducers(state, action)
