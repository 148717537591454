import { ContentFramework } from '../Library'

export interface AuthState {
  user?: TeacherData
}

export enum AUTH_ACTIONS {
  LOAD_DATA = 'AUTH_LOAD_DATA'
}

export interface AuthCredentials {
  email: string
  password: string
}

export enum AuthRoles {
  Admin = 'admin',
  User = 'user',
  Teacher = 'teacher'
}

export enum VerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  CHANGED = 'CHANGED',
  NO_EMAIL_USER = 'NO_EMAIL_USER'
}

export enum Branding {
  ASTRID = 'astrid',
  TRINITY = 'trinity'
}

export interface TeacherSettings {
  allowClassManagement: boolean
  googleClassroomFeatures: boolean
  showGeseCatagories: boolean
  branding?: Branding
  introContent?: {
    videoUrl: string
    title: string
  }
  availableContentFrameworks: ContentFramework[]
}

export interface TeacherData {
  _id: string
  email: string
  googleId?: string
  onboardingCompleted: boolean
  tutorialCompleted: boolean
  profile?: TeacherProfile
  verificationStatus: VerificationStatus
  settings: TeacherSettings
}

export interface TeacherProfile {
  firstName: string
  lastName: string
  school: string
  city: string
  country: string
  avatar?: string
}

export interface TeacherProfileUpdate {
  firstName?: string
  lastName?: string
  school?: string
  avatar?: null
}

export interface AuthLoadUser {
  type: AUTH_ACTIONS.LOAD_DATA
  user: TeacherData
}

export type AuthActions = AuthLoadUser
