import React from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'
import { trackButtonClick } from 'analytics/analytics'
import { ROUTES } from 'routes/routes'
import StudyTimeCell from 'shared/components/Overview/StudyTimeCell'
import TimePeriodToggle from 'shared/components/TimePeriodToggle/TimePeriodToggle'

import { StudentClass } from 'store/services/Classes/types'
import { StatsOverview } from 'store/services/Overview/types'
import { Statistic } from 'store/services/Statistics/types'

import styles from './InsightsTable.module.scss'

interface InsightsTableProps {
  statistics: Statistic[] | undefined
  total: StatsOverview | undefined
  selectedClass: StudentClass | undefined
  status: 'success' | 'error' | 'loading'
  showToggle: boolean
}

const InsightsTable = ({ statistics, selectedClass, total, showToggle, status = 'success' }: InsightsTableProps) => {
  return (
    <>
      <div data-role="wrapper" className={styles.toggleWrapper}>
        {showToggle && <TimePeriodToggle />}
      </div>

      {selectedClass ? <span className={styles.lastUpdated}>Updated daily</span> : null}

      <section className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th colSpan={2} className={styles.progressCell}>
                Progress
              </th>
              <th colSpan={3} className={styles.effortCell}>
                Effort
              </th>
            </tr>
            <tr>
              <th />

              {/*Progress*/}
              <th className={styles.progressCell}>Fluency</th>
              <th className={styles.progressCell}>Comprehension</th>
              {/*Progress*/}

              {/*Effort*/}
              <th className={styles.effortCell}>Time studied</th>
              <th className={styles.effortCell}>Speaking exercises (count)</th>
              {/*Effort*/}
            </tr>
          </thead>
          <tbody>
            {selectedClass ? (
              <>
                {(status === 'loading' || status === 'error') && (
                  <tr>
                    <td colSpan={6}>
                      <span className={styles.message}>
                        {status === 'loading' ? (
                          <>
                            <CircularProgress className={styles.spinner} size={18} thickness={4} />
                            Loading...
                          </>
                        ) : (
                          'Failed to load data.'
                        )}
                      </span>
                    </td>
                  </tr>
                )}
                {status === 'success' && (
                  <>
                    {!statistics || statistics.length === 0 ? (
                      <tr>
                        <td colSpan={6}>
                          <span className={styles.message}>This class is empty.</span>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {statistics.map((statistic) => {
                          return (
                            <tr key={statistic._id} data-cy="studentListRow">
                              <td>
                                <Link
                                  to={`${ROUTES.STUDENT}/${selectedClass?._id}/${statistic._id}`}
                                  onClick={() =>
                                    trackButtonClick({
                                      componentName: 'Student name',
                                      componentValue: JSON.stringify({
                                        studentId: statistic._id,
                                        classId: selectedClass?._id
                                      })
                                    })
                                  }>
                                  {`${statistic.firstName} ${statistic.lastName}`}
                                </Link>
                              </td>

                              {/*Progress*/}
                              <td>{~~statistic.progress.fluency.userScore} wpm</td>
                              <td>{~~statistic.progress.comprehension.userScore}%</td>
                              {/*Progress*/}

                              {/*Effort*/}
                              <StudyTimeCell
                                goal={statistic.effort.timeSpent.userGoal}
                                score={statistic.effort.timeSpent.userScore}>
                                {~~statistic.effort.timeSpent.userScore} min
                              </StudyTimeCell>
                              <td>{~~statistic.effort.booksCompleted.userScore}</td>
                              {/*Effort*/}
                            </tr>
                          )
                        })}
                        {total && (
                          <tr className={styles.total}>
                            <td>
                              <Link
                                to={`${ROUTES.CLASS}/${selectedClass?._id}`}
                                onClick={() =>
                                  trackButtonClick({
                                    componentName: 'Total average',
                                    componentValue: JSON.stringify({
                                      classId: selectedClass?._id
                                    })
                                  })
                                }>
                                Total Average
                              </Link>
                            </td>
                            <td>{total.stats?.progress.fluency.userScore} wpm</td>
                            <td>{total.stats?.progress.comprehension.userScore}%</td>

                            <StudyTimeCell score={total.stats?.effort.timeSpent.userScore}>
                              {total.stats?.effort.timeSpent.userScore} min
                            </StudyTimeCell>
                            <td>{total.stats?.effort.booksCompleted.userScore}</td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <tr className={styles.empty}>
                <td colSpan={6}>
                  <span className={styles.message}>Select a class in the dropdown above</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </>
  )
}

export default InsightsTable
