import React, { useMemo } from 'react'

import { Select } from '@astrid/components'

import { ContentFrameworkLabels } from '../../../store/services/Library'
import styles from './ContentFrameworkSelector.module.scss'

type Props = {
  selection: string | null
  availableFrameworks: string[]
  onSelect?: (framework: string) => void
}

export const ContentFrameworkSelector = ({ selection, availableFrameworks, onSelect }: Props) => {
  const options = useMemo(
    () => availableFrameworks.map((cf) => ({ value: cf, label: ContentFrameworkLabels[cf] })),
    [availableFrameworks]
  )
  return availableFrameworks.length > 1 ? (
    <Select
      className={styles.frameworkSelect}
      style={{ width: 300 }}
      label="Framework"
      name="framework"
      value={selection ?? ''}
      onChange={(e) => void (onSelect && onSelect(e.target.value))}
      options={options}
      testId="framework-select"
    />
  ) : null
}
