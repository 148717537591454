import API from 'shared/api/API'

class StudyTimeApi {
  static getStudyTimeByClassId = (classId: string, $limit = 8, $skip = 0) =>
    API.get(`/students-class/${classId}/study-time`, {
      params: {
        $skip,
        $limit
      }
    })
}

export default StudyTimeApi
