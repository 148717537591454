export type LibraryBookEntry = {
  bookId: string
  cefrLevel: string
  imageUrl: string
  points: number
  title: string
  description: string
  type: string
}

export type LibraryResponse = {
  shelves: {
    id: string
    title: string
    sections: {
      entries: {
        type: 'book'
        data: LibraryBookEntry
      }[]
    }[]
  }[]
}

export enum ContentFramework {
  CEFR = 'cefr',
  GESE = 'gese',
  ISE = 'ise'
}
