import React, { useCallback, useMemo } from 'react'

import { Formik } from 'formik'
import { StudentFormRowValidationSchema, StudentFormRowValues } from 'shared/components/StudentFormRow/StudentFormRow'
import * as Yup from 'yup'

import { CreateStudentData } from 'store/services/Classes/types'
import { transformPreviewStudentsFromApiToForm } from 'store/services/Classes/utils'

import styles from './SheetPreview.module.scss'
import SheetPreviewForm from './components/SheetPreviewForm'

interface SheetPreviewProps {
  parsedStudents: CreateStudentData[]
  onAddMultipleStudents: (students: StudentFormRowValues[]) => void
  onClosePreview: () => void
}

const sheetPreviewValidationSchema = Yup.object().shape({
  students: Yup.array().of(StudentFormRowValidationSchema)
})

const SheetPreview = ({ onClosePreview, parsedStudents, onAddMultipleStudents }: SheetPreviewProps) => {
  const transformedStudents: StudentFormRowValues[] = useMemo(() => {
    return transformPreviewStudentsFromApiToForm(parsedStudents)
  }, [parsedStudents])

  const singleFieldValidator = useCallback(
    async (name: keyof StudentFormRowValues, value: string): Promise<Yup.ValidationError | undefined> => {
      const schema = Yup.reach(sheetPreviewValidationSchema, name)
      try {
        await schema.validate(value)
        return undefined
      } catch (error) {
        return error as Yup.ValidationError
      }
    },
    []
  )

  const onSubmit = useCallback(
    (values: { students: StudentFormRowValues[] }) => {
      onAddMultipleStudents(values.students)
    },
    [onAddMultipleStudents]
  )

  return (
    <div className={styles.container}>
      <Formik
        validationSchema={sheetPreviewValidationSchema}
        initialValues={{ students: transformedStudents }}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={onSubmit}>
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          submitForm,
          validateForm,
          isSubmitting
        }) => {
          const handleChange = async (e: React.ChangeEvent<any>) => {
            const { name, value } = e.target
            setFieldValue(name, value, false)
          }

          const handleBlur = async (e: React.FocusEvent<any>) => {
            const { name, value } = e.target
            setFieldTouched(name, true, false)

            const error = await singleFieldValidator(name, value)
            // ts-ignore below is required because setFieldError accepts only strings, which is a mistake.
            // I have to be able to set error as undefined, because otherwise I wouldn't be able to cancel error.
            // @ts-ignore
            setFieldError(name, error?.errors[0])
          }

          return (
            <SheetPreviewForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              onClosePreview={onClosePreview}
              submitForm={submitForm}
              touched={touched}
              validateForm={validateForm}
              values={values}
              isSubmitting={isSubmitting}
            />
          )
        }}
      </Formik>
    </div>
  )
}

export default SheetPreview
