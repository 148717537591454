import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Headline, Select } from '@astrid/components'
import { ScreenNames } from 'analytics/analytics'
import { ROUTES } from 'routes/routes'
import { ApiReqState } from 'shared/api/types'
import Tabs from 'shared/components/Tabs/Tabs'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { selectUser } from 'store/services/Auth/authReducer'
import { getClasses, selectClasses, selectInsightClassId, setInsightClassId } from 'store/services/Classes/reducer'
import {
  getClassOverview,
  selectClassOverview,
  selectClassOverviewReqState,
  selectTimePeriod
} from 'store/services/Overview/reducer'
import {
  loadStatisticsByClassId,
  selectStatisticsByClassId,
  selectStatisticsReqState
} from 'store/services/Statistics/reducer'
import { loadStudyTimeByClassId, selectStudyTimeByClassId } from 'store/services/StudyTime/reducer'
import { RootState } from 'store/types'

import styles from './Insights.module.scss'
import InsightsTable from './InsightsTable/InsightsTable'
import StudyTimeTable from './StudyTimeTable/StudyTimeTable'

enum Tab {
  OVERVIEW = 'overview',
  HISTORY = 'history'
}

interface State {
  studyTimeWeekCount: number
  studyTimePage: number
  activeTab: Tab
}

const defaultState: State = {
  studyTimeWeekCount: 6,
  studyTimePage: 0,
  activeTab: Tab.OVERVIEW
}

const Insights = () => {
  useTrackScreenShown(ScreenNames.InsightsOverview)

  const history = useHistory()
  const teacher = useSelector(selectUser)
  const [state, setState] = useState<State>(defaultState)

  const dispatch = useDispatch()
  const selectedClassId = useSelector(selectInsightClassId)
  const statisticsReqState = useSelector(selectStatisticsReqState)

  const classes = useSelector(selectClasses)
  const currentStatistics = useSelector((reduxState: RootState) =>
    selectStatisticsByClassId(reduxState, selectedClassId)
  )
  const classStatistics = useSelector(selectClassOverview)
  const classStatisticsReqState = useSelector(selectClassOverviewReqState)
  const studyTimeData = useSelector((reduxState: RootState) => selectStudyTimeByClassId(reduxState, selectedClassId))
  const timePeriod = useSelector(selectTimePeriod)
  const currentClass = classes.find((c) => c._id === selectedClassId)

  const classHasUsers = currentClass && currentClass.users.length > 0

  useEffect(() => {
    if (
      classes.length > 0 &&
      (selectedClassId.length === 0 || classes.findIndex((c) => c._id === selectedClassId) === -1)
    ) {
      dispatch(setInsightClassId(classes[0]._id))
    }
  }, [classes, dispatch, selectedClassId])

  useEffect(() => {
    if (classHasUsers) {
      if (state.activeTab === Tab.HISTORY) {
        const $limit = state.studyTimeWeekCount
        const $skip = state.studyTimePage * state.studyTimeWeekCount
        dispatch(loadStudyTimeByClassId(selectedClassId, $limit, $skip))
      } else {
        // GA_pageview(`/students/${state.displayMonth ? '4weeks' : 'week'}`)
        dispatch(loadStatisticsByClassId(selectedClassId, timePeriod))
        dispatch(getClassOverview(selectedClassId, timePeriod))
      }
    }
  }, [state, selectedClassId, classHasUsers, timePeriod, dispatch])

  useEffect(() => {
    // GA_pageview(window.location.pathname)
    dispatch(getClasses())
  }, [dispatch])

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname === ROUTES.INSIGHTS) {
        setState(defaultState)
      }
    })
    return unlisten
  }, [history])

  const getStatus = () => {
    const dataFetched = statisticsReqState === ApiReqState.RESOLVED && classStatisticsReqState === ApiReqState.RESOLVED
    const fetchFailed = statisticsReqState === ApiReqState.REJECTED || classStatisticsReqState === ApiReqState.REJECTED
    if (!classHasUsers || dataFetched) {
      return 'success'
    }
    if (fetchFailed) {
      return 'error'
    }
    return 'loading'
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {teacher?.profile && (
          <Headline
            title={`${teacher.profile.firstName} ${teacher.profile.lastName}`}
            subtitle={teacher.profile.school}
            imgSrc={teacher.profile.avatar}
            className={styles.headline}
          />
        )}
        <div className={styles.filtersContainer}>
          <Select
            style={{ width: 400 }}
            name="class"
            testId="class-select-insights"
            className={styles.classSelect}
            value={selectedClassId}
            label="Class"
            onChange={(e) => dispatch(setInsightClassId(e.target.value))}
            options={classes.map(({ _id, name }) => ({ value: _id, label: name }))}
          />
        </div>
      </div>

      <Tabs<Tab>
        items={[
          {
            key: Tab.OVERVIEW,
            label: 'Overview',
            children: (
              <InsightsTable
                showToggle={!!classHasUsers}
                status={getStatus()}
                selectedClass={currentClass}
                statistics={currentStatistics}
                total={classStatistics || undefined}
              />
            )
          },
          {
            key: Tab.HISTORY,
            label: 'Time Studied History',
            children: (
              <StudyTimeTable
                classId={selectedClassId}
                studyTimeData={studyTimeData}
                onPrev={() => setState({ ...state, studyTimePage: state.studyTimePage + 1 })}
                onNext={() => setState({ ...state, studyTimePage: state.studyTimePage - 1 })}
                enableNext={state.studyTimePage > 0}
              />
            )
          }
        ]}
        activeTabKey={state.activeTab}
        onChange={(activeTab) =>
          setState((state) => ({
            ...state,
            activeTab
          }))
        }
      />
    </div>
  )
}

export default Insights
