import { AxiosPromise } from 'axios'

import API from '../../../shared/api/API'
import { LibraryResponse } from './types'

export const getLibrary = (contentFramework?: string, abortSignal?: AbortSignal): AxiosPromise<LibraryResponse> =>
  API.get('/library', {
    params: contentFramework ? { framework: contentFramework } : {},
    ...(abortSignal ? { signal: abortSignal } : {})
  })
