import React, { useCallback, useContext, useState } from 'react'

import { AlertContext, FileSearchIcon } from '@astrid/components'

import styles from './UploadClassModal.module.scss'

const XLSX_FILE_MAX_SIZE = 5 // [MB]

interface XLSXInputProps {
  onFileUpload: (file: File) => void
}

const XLSXInput = ({ onFileUpload }: XLSXInputProps) => {
  const [file, setFile] = useState<File | null>(null)
  const { showAlert } = useContext(AlertContext)

  const checkFileAndUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const allowedExtensions = /(\.xlsx|\.csv)$/i
      const selectedFile = e.target.files?.item(0)

      if (selectedFile) {
        if (!allowedExtensions.exec(selectedFile.name)) {
          showAlert('Please upload a file in XLSX or CSV format.')
          return
        }

        if (selectedFile.size >= XLSX_FILE_MAX_SIZE * 1000000) {
          showAlert(`Please upload a file smaller than ${XLSX_FILE_MAX_SIZE}MB in size.`)
          return
        }
        setFile(selectedFile)
        onFileUpload(selectedFile)
      }
    },
    [onFileUpload, showAlert]
  )

  return (
    <label htmlFor="class-sheet-upload" className={styles.xlsxInput}>
      <input
        className={styles.hidden}
        data-testid="xlsx-input"
        id="class-sheet-upload"
        type="file"
        name="class-sheet-upload"
        accept=".xlsx, .csv"
        onChange={checkFileAndUpload}
      />
      {file && <p>{file.name}</p>}
      <span>
        <FileSearchIcon aria-hidden="true" focusable="false" />
        {file ? 'Select different file' : 'Browse files'}
      </span>
    </label>
  )
}

export default XLSXInput
